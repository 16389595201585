import {RouteProps} from 'react-router-dom';
import {MainPage} from "../../../../pages/MainPage";
import {NotFoundPage} from "../../../../pages/NotFoundPage";
import {ProfilePage} from "../../../../pages/ProfilePage";
import {ArticleDetailPage, ArticlePage} from "../../../../pages/articlePage";
import {CompilationsPage} from "../../../../pages/CompilationsPage";
import {IndustriesPage} from "../../../../pages/IndustriesPage";
import {IndustriesDetailPage} from "../../../../pages/IndustriesPage/_id";
import {PodcastsPage} from "../../../../pages/PodcastsPage";
import {FeedbackPage} from "../../../../pages/FeedbackPage";
import {AuthorsPage} from "../../../../pages/AuthorsPage";
import {CompilationsDetailPage} from "../../../../pages/CompilationsPage/_uuid";
import {PodcastsDetailPage} from "../../../../pages/PodcastsPage/_uuid";
import {BooksPage} from "../../../../pages/BooksPage";
import {AudioPage} from "../../../../pages/AudioPage";
import {VideoPage} from "../../../../pages/VideoPage";


export enum AppRoutes {
    ARTICLE = 'article',
    ARTICLE_DETAIL = 'articleDetail',
    AUDIO = 'audio',
    AUTHORS = 'authors',
    BOOKS = 'books',
    COMPILATION = 'compilation',
    COMPILATION_DETAIL = 'compilationDetail',
    FEEDBACK = 'feedback',
    INDUSTRIES = 'industries',
    INDUSTRIES_DETAIL = 'industriesDetail',
    MAIN = 'main',
    NOT_FOUND = 'notFound',
    PROFILE = 'profile',
    PODCAST = 'podcast',
    PODCAST_DETAIL = 'podcastDetail',
    VIDEO = 'video',

}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.PROFILE]: '/profile',
    [AppRoutes.ARTICLE]: '/article',
    [AppRoutes.ARTICLE_DETAIL]: '/article/:id',
    [AppRoutes.AUDIO]: '/audio',
    [AppRoutes.BOOKS]: '/books',
    [AppRoutes.COMPILATION]: '/compilation',
    [AppRoutes.COMPILATION_DETAIL]: '/compilation/:uuid',
    [AppRoutes.INDUSTRIES]: '/industries',
    [AppRoutes.INDUSTRIES_DETAIL]: '/industries/:id',
    [AppRoutes.PODCAST]: '/podcast',
    [AppRoutes.PODCAST_DETAIL]: '/podcast/:uuid',
    [AppRoutes.AUTHORS]: '/authors',
    [AppRoutes.FEEDBACK]: '/feedback',
    [AppRoutes.VIDEO]: '/video',
    [AppRoutes.NOT_FOUND]: '*',
};

export const RouteTitle: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: 'Главная',
    [AppRoutes.PROFILE]: 'Профиль',
    [AppRoutes.ARTICLE]: 'Блоги',
    [AppRoutes.ARTICLE_DETAIL]: '',
    [AppRoutes.AUDIO]: 'Аудио подкасты',
    [AppRoutes.BOOKS]: 'Книги',
    [AppRoutes.COMPILATION]: 'Подборки',
    [AppRoutes.COMPILATION_DETAIL]: 'Подборка',
    [AppRoutes.INDUSTRIES]: 'Индустрия',
    [AppRoutes.INDUSTRIES_DETAIL]: 'Индустрия',
    [AppRoutes.PODCAST]: 'Подкасты',
    [AppRoutes.PODCAST_DETAIL]: '',
    [AppRoutes.AUTHORS]: 'Авторы',
    [AppRoutes.FEEDBACK]: 'Обратная связь',
    [AppRoutes.VIDEO]: 'Видео подкасты',
    [AppRoutes.NOT_FOUND]: '404',
};


export type TypeRouteTitle = {
    title: string;
}
type ExRouteProps = RouteProps & TypeRouteTitle;

export const routeConfig: Record<AppRoutes, ExRouteProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <MainPage/>,
        title: RouteTitle.main,
    },
    [AppRoutes.PROFILE]: {
        path: RoutePath.profile,
        element: <ProfilePage/>,
        title: RouteTitle.profile,
    },
    [AppRoutes.ARTICLE]: {
        path: RoutePath.article,
        element: <ArticlePage/>,
        title: RouteTitle.article,
    },
    [AppRoutes.ARTICLE_DETAIL]: {
        path: RoutePath.articleDetail,
        element: <ArticleDetailPage/>,
        title: RouteTitle.articleDetail,
    },
    [AppRoutes.AUDIO]: {
        path: RoutePath.audio,
        element: <AudioPage/>,
        title: RouteTitle.audio,
    },
    [AppRoutes.BOOKS]: {
        path: RoutePath.books,
        element: <BooksPage/>,
        title: RouteTitle.books,
    },
    [AppRoutes.COMPILATION]: {
        path: RoutePath.compilation,
        element: <CompilationsPage/>,
        title: RouteTitle.compilation,
    },
    [AppRoutes.COMPILATION_DETAIL]: {
        path: RoutePath.compilationDetail,
        element: <CompilationsDetailPage/>,
        title: RouteTitle.compilationDetail,
    },
    [AppRoutes.INDUSTRIES]: {
        path: RoutePath.industries,
        element: <IndustriesPage/>,
        title: RouteTitle.industries,
    },
    [AppRoutes.INDUSTRIES_DETAIL]: {
        path: RoutePath.industriesDetail,
        element: <IndustriesDetailPage/>,
        title: RouteTitle.industriesDetail,
    },
    [AppRoutes.PODCAST]: {
        path: RoutePath.podcast,
        element: <PodcastsPage/>,
        title: RouteTitle.podcast,
    },
    [AppRoutes.PODCAST_DETAIL]: {
        path: RoutePath.podcastDetail,
        element: <PodcastsDetailPage/>,
        title: RouteTitle.podcastDetail,
    },
    [AppRoutes.AUTHORS]: {
        path: RoutePath.authors,
        element: <AuthorsPage/>,
        title: RouteTitle.authors,
    },
    [AppRoutes.FEEDBACK]: {
        path: RoutePath.feedback,
        element: <FeedbackPage/>,
        title: RouteTitle.feedback,
    },
    [AppRoutes.VIDEO]: {
        path: RoutePath.video,
        element: <VideoPage/>,
        title: RouteTitle.video,
    },
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.notFound,
        element: <NotFoundPage/>,
        title: RouteTitle.notFound,
    },
};
