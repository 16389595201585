import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Layout, Menu, MenuProps} from "antd";

import {FileTextOutlined, FolderOpenOutlined, HomeOutlined} from '@ant-design/icons';
import {AppRoutes, RoutePath, RouteTitle} from "../../../../app/providers/router/config/routeConfig";
import {classNames} from "../../../../lib/ClassNames/ClassNames";

interface UserProfileProps {
    className?: string;
}

type MenuItem = Required<MenuProps>['items'][number];
const {Sider} = Layout;

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    path?: string,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        path
    } as MenuItem;
}

const items: MenuItem[] = [
    getItem(RouteTitle.main, AppRoutes.MAIN, <HomeOutlined/>, RoutePath.main),
    getItem(RouteTitle.article, AppRoutes.ARTICLE, <FileTextOutlined/>, RoutePath.article),
    getItem(RouteTitle.podcast, AppRoutes.PODCAST, <FileTextOutlined/>, RoutePath.podcast),
    getItem(RouteTitle.compilation, AppRoutes.COMPILATION, <FileTextOutlined/>, RoutePath.compilation),
    getItem(RouteTitle.industries, AppRoutes.INDUSTRIES, <FileTextOutlined/>, RoutePath.industries),

    getItem('Каталог справочников', 'directory', <FolderOpenOutlined/>, undefined, [
        getItem(RouteTitle.authors, AppRoutes.AUTHORS, undefined, RoutePath.authors),
        getItem(RouteTitle.audio, AppRoutes.AUDIO, undefined, RoutePath.audio),
        getItem(RouteTitle.books, AppRoutes.BOOKS, undefined, RoutePath.books),
        getItem(RouteTitle.video, AppRoutes.VIDEO, undefined, RoutePath.video),
    ]),

    getItem(RouteTitle.feedback, AppRoutes.FEEDBACK, <FileTextOutlined/>, RoutePath.feedback),
];

export const Sidebar = ({className = ''}: UserProfileProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const getNameByKey: any = (array: any, key: string) => {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i]?.key === key) {
                    return array[i]
                } else {
                    const childrenByKey = getNameByKey(array[i].children, key)
                    if (childrenByKey) return childrenByKey
                }
            }
        }
        return null
    }

    const onMenuClick = (event: MenuItem) => {
        const url = getNameByKey(items, event?.key)
        if (url?.path) {
            navigate(url.path)
        }
    }

    return (
        <Sider className={classNames('', {}, [className])} collapsible collapsed={collapsed}
               onCollapse={value => setCollapsed(value)}>
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onClick={onMenuClick}/>
        </Sider>
    );
};
