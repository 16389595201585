import {ApolloClient, from, HttpLink, InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import {message as antdMessage} from "antd";
import {getAccessTokenPromise} from "../auth/lib/helpers";
import {BEARER} from "../auth/lib/constant";


const uri: string = process.env.REACT_APP_BACKEND as string;
const httpLink = new HttpLink({uri});

export const authMiddleware = setContext(async (_, {headers}) => {
    const accessToken = await getAccessTokenPromise()
    return {
        headers: {
            ...headers,
            Authorization: accessToken ? `${BEARER} ${accessToken}` : ''
        }
    }
})

const errorMiddleware = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({message, ...details}) => {
                antdMessage.error(`GraphQL Error: ${message}`)
            }
        );
    }

    if (networkError) {
        antdMessage.error(`Network Error: ${networkError}`)
    }
});

export const client = new ApolloClient({
    link: from([authMiddleware, errorMiddleware, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-first",
            errorPolicy: "all",
        },
        query: {
            fetchPolicy: "cache-first",
            errorPolicy: "all",
        },
    },
});
