import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import {routeConfig} from "../config/routeConfig";
import LoadingSpinner from "../../../../components/ui/LoadingPage/LoadingSpinner";


const AppRouter = () => (
    <Routes>
        {Object.values(routeConfig).map(({element, path}) => (
            <Route
                key={path}
                path={path}
                element={(
                    <Suspense fallback={<LoadingSpinner/>}>
                        <main>
                            {element}
                        </main>
                    </Suspense>
                )}
            />
        ))}
    </Routes>

);

export default AppRouter;
