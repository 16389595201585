import React from 'react';
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import {ApolloProvider} from "@apollo/client";
import {client} from "./app/providers/apollo/config";
import {AuthProvider} from "./app/providers/auth";

import './assets/scss/main.scss';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ApolloProvider client={client}>
        <AuthProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </AuthProvider>
    </ApolloProvider>
);
