import React, {useEffect, useMemo, useState} from 'react';
import {AuthContext, AuthContextProps} from "../lib/AuthContext";
import {getToken, removeToken, setToken} from "../lib/helpers";
import {useApolloClient} from "@apollo/client";
import {AuthorizationDto, useLogoutMutation, UserWithToken, useSigninLocalMutation} from "../../../../graphql/scheme";


const AuthProvider = ({children}: { children: React.ReactNode }) => {
    const isAuthenticated = !!getToken();

    const [userData, setUserData] = useState<UserWithToken>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isAuthenticated);

    const [signIn] = useSigninLocalMutation();
    const [logout] = useLogoutMutation();

    const client = useApolloClient();

    useEffect(() => {
        if (!isLoggedIn) {
            removeToken();
            setUserData(undefined);
        }
    }, [isLoggedIn])


    const onLogout = () => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);

            logout()
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(error)
                })
                .finally(() => {
                    setIsLoggedIn(false)
                    setIsLoading(false);

                    client.clearStore();
                })

        })
    }

    const login = (body: AuthorizationDto): Promise<UserWithToken> => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);

            signIn({variables: {body}})
                .then(({data}) => {
                    setToken(data!.signinLocal.accessToken)
                    setUserData(data?.signinLocal);
                    setIsLoggedIn(true)

                    resolve(data!.signinLocal);
                })
                .catch((error) => {
                    setIsLoggedIn(false)
                    removeToken()
                    reject(error)
                })
                .finally(() => {
                    setIsLoading(false);
                })

        })

    };

    const defaultProps = useMemo<AuthContextProps>(() => ({
        user: userData,
        login: login,
        logout: onLogout,
        isLoggedIn,
        isLoading
    }), [userData, isLoggedIn]);

    return (
        <AuthContext.Provider value={defaultProps}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
