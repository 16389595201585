import {createContext, useContext} from 'react';
import {AuthorizationDto, UserWithToken} from "../../../../graphql/scheme";

export interface AuthContextProps {
    user?: UserWithToken;
    isLoading: boolean,
    isLoggedIn: boolean,
    login: (body: AuthorizationDto) => Promise<UserWithToken>;
    logout: () => Promise<unknown>;
}

export const AuthContext = createContext({} as AuthContextProps);

export const useAuthContext = () => useContext(AuthContext);

