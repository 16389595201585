import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Article = {
  __typename?: 'Article';
  annotation: Scalars['String'];
  articleContents?: Maybe<Array<ArticleBlock>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  industry?: Maybe<Array<Industry>>;
  isActive: Scalars['Boolean'];
  preview: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ArticleBlock = {
  __typename?: 'ArticleBlock';
  articleId: Scalars['Float'];
  contentBlockId: Scalars['Float'];
  contentBlocks?: Maybe<ContentBlock>;
  id: Scalars['Float'];
  order: Scalars['Float'];
};

export type ArticlePagination = {
  __typename?: 'ArticlePagination';
  count: Scalars['Int'];
  data: Array<Article>;
};

export type ArticleQuery = {
  industry?: InputMaybe<Array<ManyToManyId>>;
  limit?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type AudioEntity = {
  __typename?: 'AudioEntity';
  annotation?: Maybe<Scalars['String']>;
  audio: Scalars['String'];
  authors?: Maybe<Array<Author>>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  uuid: Scalars['String'];
};

export type AudioEntityWithEntity = {
  __typename?: 'AudioEntityWithEntity';
  annotation?: Maybe<Scalars['String']>;
  audio: Scalars['String'];
  authors?: Maybe<Array<Author>>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['String']>;
  entity?: Maybe<Array<Entity>>;
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  uuid: Scalars['String'];
};

export type Author = {
  __typename?: 'Author';
  birthday?: Maybe<Scalars['String']>;
  deathday?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  patronymic?: Maybe<Scalars['String']>;
};

export type AuthorizationDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type BookEntityWithRelasion = {
  __typename?: 'BookEntityWithRelasion';
  annotation?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Author>>;
  book: Scalars['String'];
  counterPages?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  entity?: Maybe<Array<Entity>>;
  image?: Maybe<Scalars['String']>;
  legalPossibilitiesUse?: Maybe<Scalars['String']>;
  placePublication?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  uuid: Scalars['String'];
  yearPublisher?: Maybe<Scalars['Float']>;
};

export type CheckProgressInput = {
  audioUuid?: InputMaybe<Scalars['String']>;
  bookUuid?: InputMaybe<Scalars['String']>;
  videoUuid?: InputMaybe<Scalars['String']>;
};

export type ContentBlock = {
  __typename?: 'ContentBlock';
  content?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  mediaBlocks?: Maybe<Array<MediaBlock>>;
  type: Scalars['String'];
};

export type CreateArticleBlockInput = {
  articleId: Scalars['Float'];
  contentBlockId: Scalars['Float'];
  order: Scalars['Float'];
};

export type CreateArticleInput = {
  annotation: Scalars['String'];
  industry: Array<ManyToManyId>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  preview: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CreateAudioEntityInput = {
  annotation: Scalars['String'];
  audio: Scalars['String'];
  authors: Array<Scalars['Float']>;
  duration: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
};

export type CreateAuthorInput = {
  birthday?: InputMaybe<Scalars['String']>;
  deathday?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  patronymic?: InputMaybe<Scalars['String']>;
};

export type CreateBookEntityInput = {
  annotation: Scalars['String'];
  authors: Array<Scalars['Float']>;
  book: Scalars['String'];
  counterPages: Scalars['Float'];
  image: Scalars['String'];
  legalPossibilitiesUse: Scalars['String'];
  placePublication: Scalars['String'];
  title: Scalars['String'];
  yearPublisher: Scalars['Float'];
};

export type CreateContentBlockInput = {
  content?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateEntityInput = {
  annotation?: InputMaybe<Scalars['String']>;
  audios?: InputMaybe<Array<ManyToManyUuid>>;
  books?: InputMaybe<Array<ManyToManyUuid>>;
  image?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Array<ManyToManyId>>;
  isPublish?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: TypeEntity;
  typeBlock: TypeBlock;
  videos?: InputMaybe<Array<ManyToManyUuid>>;
};

export type CreateFavoriteInput = {
  entityUuid: Scalars['String'];
};

export type CreateFeedbackDto = {
  email: Scalars['String'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type CreateIndustryInput = {
  color: Scalars['String'];
  content: Scalars['String'];
  description: Scalars['String'];
  isMain: Scalars['Boolean'];
  order: Scalars['Float'];
  photos: Array<Photo>;
  title: Scalars['String'];
};

export type CreateMediaBlockInput = {
  contentBlockId: Scalars['Float'];
  fileLink: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateProgressInput = {
  audioUuid?: InputMaybe<Scalars['String']>;
  bookUuid?: InputMaybe<Scalars['String']>;
  percent: Scalars['Float'];
  position: Scalars['String'];
  videoUuid?: InputMaybe<Scalars['String']>;
};

export type CreateVideoEntityInput = {
  annotation: Scalars['String'];
  authors: Array<Scalars['Float']>;
  duration: Scalars['String'];
  image: Scalars['String'];
  title: Scalars['String'];
  video: Scalars['String'];
};

export type EmailDto = {
  email: Scalars['String'];
};

export type Entity = {
  __typename?: 'Entity';
  annotation?: Maybe<Scalars['String']>;
  audios?: Maybe<Array<AudioEntity>>;
  books?: Maybe<Array<BookEntityWithRelasion>>;
  createdAt: Scalars['DateTime'];
  image?: Maybe<Scalars['String']>;
  industry?: Maybe<Array<Industry>>;
  isPublish: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: TypeEntity;
  typeBlock: TypeBlock;
  uuid: Scalars['String'];
  videos?: Maybe<Array<VideoEntity>>;
};

export type EntityPagination = {
  __typename?: 'EntityPagination';
  count: Scalars['Int'];
  data: Array<Entity>;
};

export type EntityQuery = {
  industry?: InputMaybe<Array<ManyToManyId>>;
  limit?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type Favorite = {
  __typename?: 'Favorite';
  createdAt: Scalars['DateTime'];
  entity: Entity;
  id: Scalars['ID'];
  user: User;
};

export type FavoritePagination = {
  __typename?: 'FavoritePagination';
  count: Scalars['Int'];
  data: Array<Favorite>;
};

export type FavoriteQuery = {
  limit?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  email: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type Industry = {
  __typename?: 'Industry';
  color: Scalars['String'];
  content: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isMain: Scalars['Boolean'];
  order: Scalars['Float'];
  photos: Scalars['String'];
  title: Scalars['String'];
};

export type ManyToManyId = {
  id: Scalars['Float'];
};

export type ManyToManyUuid = {
  uuid: Scalars['String'];
};

export type MediaBlock = {
  __typename?: 'MediaBlock';
  fileLink: Scalars['String'];
  id: Scalars['Float'];
  label?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  articleBlockCreate: ArticleBlock;
  articleBlockRemove: ArticleBlock;
  articleBlockUpdate: ArticleBlock;
  articleBlockUpdateOrdersInput: Array<ArticleBlock>;
  articleCreate: Article;
  articleRemove: Article;
  articleUpdate: Article;
  audioCreate: AudioEntity;
  audioRemove: AudioEntity;
  audioUpdate: AudioEntity;
  authorCreate: Author;
  authorRemove: Author;
  authorUpdate: Author;
  bookCreate: BookEntityWithRelasion;
  bookRemove: BookEntityWithRelasion;
  bookUpdate: BookEntityWithRelasion;
  contentBlockCreate: ContentBlock;
  contentBlockRemove: ContentBlock;
  contentBlockUpdate: ContentBlock;
  entityCreate: Entity;
  entityRemove: Entity;
  entityUpdate: Entity;
  favoriteCreate: Favorite;
  favoriteRemove: Scalars['Boolean'];
  feedbackCreate: Feedback;
  feedbackDelete: Feedback;
  feedbackUpdate: Feedback;
  industryCreate: Industry;
  industryRemove: Industry;
  industryUpdate: Industry;
  logout: Scalars['Boolean'];
  mediaBlockCreate: MediaBlock;
  mediaBlockRemove: MediaBlock;
  mediaBlockUpdate: MediaBlock;
  progressRemove: Scalars['Boolean'];
  progressRemoveAll: Scalars['Boolean'];
  progressUpsert: Progress;
  refreshTokens: User;
  resetPassword: UserWithToken;
  sendEmailCodeForPassword: Scalars['Boolean'];
  signinLocal: UserWithToken;
  signupLocal: Scalars['Boolean'];
  userBan: User;
  userRemove: User;
  videoCreate: VideoEntity;
  videoRemove: VideoEntity;
  videoUpdate: VideoEntity;
};


export type MutationArticleBlockCreateArgs = {
  body: CreateArticleBlockInput;
};


export type MutationArticleBlockRemoveArgs = {
  id: Scalars['Float'];
};


export type MutationArticleBlockUpdateArgs = {
  body: UpdateArticleBlockInput;
  id: Scalars['Float'];
};


export type MutationArticleBlockUpdateOrdersInputArgs = {
  body: UpdateOrdersInput;
};


export type MutationArticleCreateArgs = {
  body: CreateArticleInput;
};


export type MutationArticleRemoveArgs = {
  id: Scalars['Float'];
};


export type MutationArticleUpdateArgs = {
  body: UpdateArticleInput;
  id: Scalars['Float'];
};


export type MutationAudioCreateArgs = {
  body: CreateAudioEntityInput;
};


export type MutationAudioRemoveArgs = {
  uuid: Scalars['String'];
};


export type MutationAudioUpdateArgs = {
  body: UpdateAudioEntityInput;
  uuid: Scalars['String'];
};


export type MutationAuthorCreateArgs = {
  body: CreateAuthorInput;
};


export type MutationAuthorRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationAuthorUpdateArgs = {
  body: UpdateAuthorInput;
  id: Scalars['Float'];
};


export type MutationBookCreateArgs = {
  body: CreateBookEntityInput;
};


export type MutationBookRemoveArgs = {
  uuid: Scalars['String'];
};


export type MutationBookUpdateArgs = {
  body: UpdateBookEntityInput;
  uuid: Scalars['String'];
};


export type MutationContentBlockCreateArgs = {
  body: CreateContentBlockInput;
};


export type MutationContentBlockRemoveArgs = {
  id: Scalars['Float'];
};


export type MutationContentBlockUpdateArgs = {
  body: UpdateContentBlockInput;
  id: Scalars['Float'];
};


export type MutationEntityCreateArgs = {
  body: CreateEntityInput;
};


export type MutationEntityRemoveArgs = {
  uuid: Scalars['String'];
};


export type MutationEntityUpdateArgs = {
  body: UpdateEntityInput;
  uuid: Scalars['String'];
};


export type MutationFavoriteCreateArgs = {
  body: CreateFavoriteInput;
};


export type MutationFavoriteRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationFeedbackCreateArgs = {
  body: CreateFeedbackDto;
};


export type MutationFeedbackDeleteArgs = {
  id: Scalars['Float'];
};


export type MutationFeedbackUpdateArgs = {
  body: CreateFeedbackDto;
  id: Scalars['Float'];
};


export type MutationIndustryCreateArgs = {
  body: CreateIndustryInput;
};


export type MutationIndustryRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationIndustryUpdateArgs = {
  body: UpdateIndustryInput;
  id: Scalars['Float'];
};


export type MutationMediaBlockCreateArgs = {
  body: CreateMediaBlockInput;
};


export type MutationMediaBlockRemoveArgs = {
  id: Scalars['Float'];
};


export type MutationMediaBlockUpdateArgs = {
  body: UpdateMediaBlockInput;
  id: Scalars['Float'];
};


export type MutationProgressRemoveArgs = {
  uuid: Scalars['String'];
};


export type MutationProgressUpsertArgs = {
  body: CreateProgressInput;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordInput;
};


export type MutationSendEmailCodeForPasswordArgs = {
  body: EmailDto;
};


export type MutationSigninLocalArgs = {
  body: AuthorizationDto;
};


export type MutationSignupLocalArgs = {
  body: EmailDto;
};


export type MutationUserBanArgs = {
  body: UserBan;
  id: Scalars['Float'];
};


export type MutationUserRemoveArgs = {
  id: Scalars['Int'];
};


export type MutationVideoCreateArgs = {
  body: CreateVideoEntityInput;
};


export type MutationVideoRemoveArgs = {
  uuid: Scalars['String'];
};


export type MutationVideoUpdateArgs = {
  body: UpdateVideoEntityInput;
  uuid: Scalars['String'];
};

export type Photo = {
  photo: Scalars['String'];
};

export type Progress = {
  __typename?: 'Progress';
  createdAt: Scalars['DateTime'];
  percent: Scalars['String'];
  position: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  articleBlockFindAll: Array<ArticleBlock>;
  articleBlockFindOne: ArticleBlock;
  articleFindAll: ArticlePagination;
  articleFindAllForAdmin: ArticlePagination;
  articleFindOne: Article;
  audioFindAll: Array<AudioEntity>;
  audioFindOne: AudioEntity;
  authorFindAll: Array<Author>;
  authorFindOne: Author;
  bookFindAll: Array<BookEntityWithRelasion>;
  bookFindOne: BookEntityWithRelasion;
  contentBlockFindAll: Array<ContentBlock>;
  contentBlockFindOne: ContentBlock;
  favoriteFindAll: FavoritePagination;
  feedbackFindOne: Feedback;
  feedbackFindall: Array<Feedback>;
  globalSearch: Search;
  industryFindAll: Array<Industry>;
  industryFindOne: Industry;
  me: User;
  mediaBlock: Array<MediaBlock>;
  mediaBlockDetail: MediaBlock;
  myProgress: UserWithProgress;
  podcastFindAll: EntityPagination;
  podcastFindAllForAdmin: EntityPagination;
  podcastFindOne: Entity;
  progressCheck: Array<Progress>;
  selectionFindAll: EntityPagination;
  selectionFindAllForAdmin: EntityPagination;
  selectionFindOne: Entity;
  userFindAll: Array<User>;
  userFindOne: User;
  videoFindAll: Array<VideoEntity>;
  videoFindOne: VideoEntity;
};


export type QueryArticleBlockFindOneArgs = {
  id: Scalars['Float'];
};


export type QueryArticleFindAllArgs = {
  newsQuery: ArticleQuery;
};


export type QueryArticleFindAllForAdminArgs = {
  newsQuery: ArticleQuery;
};


export type QueryArticleFindOneArgs = {
  id: Scalars['Float'];
};


export type QueryAudioFindOneArgs = {
  uuid: Scalars['String'];
};


export type QueryAuthorFindOneArgs = {
  id: Scalars['Int'];
};


export type QueryBookFindOneArgs = {
  uuid: Scalars['String'];
};


export type QueryContentBlockFindOneArgs = {
  id: Scalars['Float'];
};


export type QueryFavoriteFindAllArgs = {
  query: FavoriteQuery;
};


export type QueryFeedbackFindOneArgs = {
  id: Scalars['Float'];
};


export type QueryGlobalSearchArgs = {
  searchQuery: SearchQuery;
};


export type QueryIndustryFindOneArgs = {
  id: Scalars['Int'];
};


export type QueryMediaBlockDetailArgs = {
  id: Scalars['Float'];
};


export type QueryPodcastFindAllArgs = {
  entityQuery: EntityQuery;
};


export type QueryPodcastFindAllForAdminArgs = {
  entityQuery: EntityQuery;
};


export type QueryPodcastFindOneArgs = {
  uuid: Scalars['String'];
};


export type QueryProgressCheckArgs = {
  body: CheckProgressInput;
};


export type QuerySelectionFindAllArgs = {
  entityQuery: EntityQuery;
};


export type QuerySelectionFindAllForAdminArgs = {
  entityQuery: EntityQuery;
};


export type QuerySelectionFindOneArgs = {
  uuid: Scalars['String'];
};


export type QueryUserFindOneArgs = {
  id: Scalars['Int'];
};


export type QueryVideoFindOneArgs = {
  uuid: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type Search = {
  __typename?: 'Search';
  articles?: Maybe<Array<Article>>;
  audios?: Maybe<Array<AudioEntityWithEntity>>;
  books?: Maybe<Array<BookEntityWithRelasion>>;
  podcast?: Maybe<Array<Entity>>;
  selection?: Maybe<Array<Entity>>;
  videos?: Maybe<Array<VideoEntityWithEntity>>;
};

export type SearchQuery = {
  industry?: InputMaybe<Array<ManyToManyId>>;
  isArticle?: InputMaybe<Scalars['Boolean']>;
  isAudio?: InputMaybe<Scalars['Boolean']>;
  isBook?: InputMaybe<Scalars['Boolean']>;
  isPodcast?: InputMaybe<Scalars['Boolean']>;
  isSelection?: InputMaybe<Scalars['Boolean']>;
  isVideo?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

/** Типы блока */
export enum TypeBlock {
  Main = 'MAIN',
  New = 'NEW'
}

/** Типы сущностей */
export enum TypeEntity {
  Podcast = 'PODCAST',
  Selection = 'SELECTION'
}

export type UpdateArticleBlockInput = {
  articleId?: InputMaybe<Scalars['Float']>;
  contentBlockId?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<Scalars['Float']>;
};

export type UpdateArticleInput = {
  annotation?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Array<ManyToManyId>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  preview?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateAudioEntityInput = {
  annotation?: InputMaybe<Scalars['String']>;
  audio?: InputMaybe<Scalars['String']>;
  authors?: InputMaybe<Array<Scalars['Float']>>;
  duration?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateAuthorInput = {
  birthday?: InputMaybe<Scalars['String']>;
  deathday?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  patronymic?: InputMaybe<Scalars['String']>;
};

export type UpdateBookEntityInput = {
  annotation?: InputMaybe<Scalars['String']>;
  authors?: InputMaybe<Array<Scalars['Float']>>;
  book?: InputMaybe<Scalars['String']>;
  counterPages?: InputMaybe<Scalars['Float']>;
  image?: InputMaybe<Scalars['String']>;
  legalPossibilitiesUse?: InputMaybe<Scalars['String']>;
  placePublication?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  yearPublisher?: InputMaybe<Scalars['Float']>;
};

export type UpdateContentBlockInput = {
  content?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateEntityInput = {
  annotation?: InputMaybe<Scalars['String']>;
  audios?: InputMaybe<Array<ManyToManyUuid>>;
  books?: InputMaybe<Array<ManyToManyUuid>>;
  image?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Array<ManyToManyId>>;
  isPublish?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TypeEntity>;
  typeBlock?: InputMaybe<TypeBlock>;
  videos?: InputMaybe<Array<ManyToManyUuid>>;
};

export type UpdateIndustryInput = {
  color?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Float']>;
  photos?: InputMaybe<Array<Photo>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMediaBlockInput = {
  contentBlockId?: InputMaybe<Scalars['Float']>;
  fileLink?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  articleBlockId: Scalars['Float'];
  order: Scalars['Float'];
};

export type UpdateOrdersInput = {
  data: Array<UpdateOrderInput>;
};

export type UpdateVideoEntityInput = {
  annotation?: InputMaybe<Scalars['String']>;
  authors?: InputMaybe<Array<Scalars['Float']>>;
  duration?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  isBanned: Scalars['Boolean'];
  isEmailConfirmation: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};

export type UserBan = {
  isBanned: Scalars['Boolean'];
};

export type UserWithProgress = {
  __typename?: 'UserWithProgress';
  email: Scalars['String'];
  id: Scalars['ID'];
  isBanned: Scalars['Boolean'];
  isEmailConfirmation: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  progress?: Maybe<Array<Progress>>;
  role: Scalars['String'];
};

export type UserWithToken = {
  __typename?: 'UserWithToken';
  accessToken: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isBanned: Scalars['Boolean'];
  isEmailConfirmation: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  role: Scalars['String'];
};

export type VideoEntity = {
  __typename?: 'VideoEntity';
  annotation?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Author>>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  uuid: Scalars['String'];
  video: Scalars['String'];
};

export type VideoEntityWithEntity = {
  __typename?: 'VideoEntityWithEntity';
  annotation?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Author>>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['String']>;
  entity?: Maybe<Array<Entity>>;
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  uuid: Scalars['String'];
  video: Scalars['String'];
};

export type ArticleFragmentFragment = { __typename?: 'Article', id: number, title: string, slug: string, preview: string, isActive: boolean, annotation: string, createdAt: any };

export type ArticleCreateMutationVariables = Exact<{
  body: CreateArticleInput;
}>;


export type ArticleCreateMutation = { __typename?: 'Mutation', articleCreate: { __typename?: 'Article', id: number, title: string, slug: string, preview: string, isActive: boolean, annotation: string, createdAt: any } };

export type ArticleUpdateMutationVariables = Exact<{
  body: UpdateArticleInput;
  articleUpdateId: Scalars['Float'];
}>;


export type ArticleUpdateMutation = { __typename?: 'Mutation', articleUpdate: { __typename?: 'Article', id: number, title: string, slug: string, preview: string, isActive: boolean, annotation: string, createdAt: any } };

export type ArticleRemoveMutationVariables = Exact<{
  articleRemoveId: Scalars['Float'];
}>;


export type ArticleRemoveMutation = { __typename?: 'Mutation', articleRemove: { __typename?: 'Article', id: number } };

export type ArticleFindAllForAdminQueryVariables = Exact<{
  newsQuery: ArticleQuery;
}>;


export type ArticleFindAllForAdminQuery = { __typename?: 'Query', articleFindAllForAdmin: { __typename?: 'ArticlePagination', count: number, data: Array<{ __typename?: 'Article', id: number, title: string, slug: string, preview: string, isActive: boolean, annotation: string, createdAt: any, industry?: Array<{ __typename?: 'Industry', id: string }> | null }> } };

export type ArticleFindOneQueryVariables = Exact<{
  articleFindOneId: Scalars['Float'];
}>;


export type ArticleFindOneQuery = { __typename?: 'Query', articleFindOne: { __typename?: 'Article', id: number, title: string, slug: string, preview: string, isActive: boolean, annotation: string, createdAt: any, industry?: Array<{ __typename?: 'Industry', id: string }> | null, articleContents?: Array<{ __typename?: 'ArticleBlock', id: number, order: number, articleId: number, contentBlockId: number, contentBlocks?: { __typename?: 'ContentBlock', content?: string | null, id: number, type: string, mediaBlocks?: Array<{ __typename?: 'MediaBlock', fileLink: string, id: number, label?: string | null, type: string }> | null } | null }> | null } };

export type ArticleBlockFragmentFragment = { __typename?: 'ArticleBlock', id: number, order: number, articleId: number, contentBlockId: number };

export type ArticleBlockCreateMutationVariables = Exact<{
  body: CreateArticleBlockInput;
}>;


export type ArticleBlockCreateMutation = { __typename?: 'Mutation', articleBlockCreate: { __typename?: 'ArticleBlock', id: number, order: number, articleId: number, contentBlockId: number, contentBlocks?: { __typename?: 'ContentBlock', content?: string | null, id: number, type: string, mediaBlocks?: Array<{ __typename?: 'MediaBlock', fileLink: string, id: number, label?: string | null, type: string }> | null } | null } };

export type ArticleBlockUpdateMutationVariables = Exact<{
  body: UpdateArticleBlockInput;
  articleBlockUpdateId: Scalars['Float'];
}>;


export type ArticleBlockUpdateMutation = { __typename?: 'Mutation', articleBlockUpdate: { __typename?: 'ArticleBlock', id: number, order: number, articleId: number, contentBlockId: number, contentBlocks?: { __typename?: 'ContentBlock', content?: string | null, id: number, type: string, mediaBlocks?: Array<{ __typename?: 'MediaBlock', fileLink: string, id: number, label?: string | null, type: string }> | null } | null } };

export type ArticleBlockRemoveMutationVariables = Exact<{
  articleBlockRemoveId: Scalars['Float'];
}>;


export type ArticleBlockRemoveMutation = { __typename?: 'Mutation', articleBlockRemove: { __typename?: 'ArticleBlock', id: number } };

export type AudioFragmentFragment = { __typename?: 'AudioEntity', uuid: string, title: string, image?: string | null, audio: string, annotation?: string | null, duration?: string | null };

export type AudioCreateMutationVariables = Exact<{
  body: CreateAudioEntityInput;
}>;


export type AudioCreateMutation = { __typename?: 'Mutation', audioCreate: { __typename?: 'AudioEntity', uuid: string, title: string, image?: string | null, audio: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null } };

export type AudioUpdateMutationVariables = Exact<{
  body: UpdateAudioEntityInput;
  uuid: Scalars['String'];
}>;


export type AudioUpdateMutation = { __typename?: 'Mutation', audioUpdate: { __typename?: 'AudioEntity', uuid: string, title: string, image?: string | null, audio: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null } };

export type AudioRemoveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type AudioRemoveMutation = { __typename?: 'Mutation', audioRemove: { __typename?: 'AudioEntity', uuid: string } };

export type AudioFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type AudioFindAllQuery = { __typename?: 'Query', audioFindAll: Array<{ __typename?: 'AudioEntity', uuid: string, title: string, image?: string | null, audio: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> };

export type UserWithTokenFragmentFragment = { __typename?: 'UserWithToken', email: string, isBanned: boolean, isEmailConfirmation: boolean, name?: string | null, role: string };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type SigninLocalMutationVariables = Exact<{
  body: AuthorizationDto;
}>;


export type SigninLocalMutation = { __typename?: 'Mutation', signinLocal: { __typename?: 'UserWithToken', id: string, accessToken: string, refreshToken: string, email: string, isBanned: boolean, isEmailConfirmation: boolean, name?: string | null, role: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', email: string, id: string, isBanned: boolean, isEmailConfirmation: boolean, name?: string | null, role: string } };

export type AuthorFragmentFragment = { __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string };

export type AuthorCreateMutationVariables = Exact<{
  body: CreateAuthorInput;
}>;


export type AuthorCreateMutation = { __typename?: 'Mutation', authorCreate: { __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string } };

export type AuthorUpdateMutationVariables = Exact<{
  body: UpdateAuthorInput;
  authorUpdateId: Scalars['Float'];
}>;


export type AuthorUpdateMutation = { __typename?: 'Mutation', authorUpdate: { __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string } };

export type AuthorRemoveMutationVariables = Exact<{
  authorRemoveId: Scalars['Int'];
}>;


export type AuthorRemoveMutation = { __typename?: 'Mutation', authorRemove: { __typename?: 'Author', id: string } };

export type AuthorFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthorFindAllQuery = { __typename?: 'Query', authorFindAll: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> };

export type BookFragmentFragment = { __typename?: 'BookEntityWithRelasion', annotation?: string | null, book: string, counterPages?: number | null, image?: string | null, legalPossibilitiesUse?: string | null, placePublication?: string | null, title: string, uuid: string, yearPublisher?: number | null };

export type BookCreateMutationVariables = Exact<{
  body: CreateBookEntityInput;
}>;


export type BookCreateMutation = { __typename?: 'Mutation', bookCreate: { __typename?: 'BookEntityWithRelasion', annotation?: string | null, book: string, counterPages?: number | null, image?: string | null, legalPossibilitiesUse?: string | null, placePublication?: string | null, title: string, uuid: string, yearPublisher?: number | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null } };

export type BookUpdateMutationVariables = Exact<{
  body: UpdateBookEntityInput;
  uuid: Scalars['String'];
}>;


export type BookUpdateMutation = { __typename?: 'Mutation', bookUpdate: { __typename?: 'BookEntityWithRelasion', annotation?: string | null, book: string, counterPages?: number | null, image?: string | null, legalPossibilitiesUse?: string | null, placePublication?: string | null, title: string, uuid: string, yearPublisher?: number | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null } };

export type BookRemoveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type BookRemoveMutation = { __typename?: 'Mutation', bookRemove: { __typename?: 'BookEntityWithRelasion', uuid: string } };

export type BookFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type BookFindAllQuery = { __typename?: 'Query', bookFindAll: Array<{ __typename?: 'BookEntityWithRelasion', annotation?: string | null, book: string, counterPages?: number | null, image?: string | null, legalPossibilitiesUse?: string | null, placePublication?: string | null, title: string, uuid: string, yearPublisher?: number | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> };

export type BookFindAllLiteQueryVariables = Exact<{ [key: string]: never; }>;


export type BookFindAllLiteQuery = { __typename?: 'Query', bookFindAll: Array<{ __typename?: 'BookEntityWithRelasion', title: string, book: string, authors?: Array<{ __typename?: 'Author', lastName: string, patronymic?: string | null, firstName?: string | null, id: string }> | null }> };

export type ContentBlockFragmentFragment = { __typename?: 'ContentBlock', content?: string | null, id: number, type: string };

export type ContentBlockCreateMutationVariables = Exact<{
  body: CreateContentBlockInput;
}>;


export type ContentBlockCreateMutation = { __typename?: 'Mutation', contentBlockCreate: { __typename?: 'ContentBlock', content?: string | null, id: number, type: string } };

export type ContentBlockUpdateMutationVariables = Exact<{
  body: UpdateContentBlockInput;
  contentBlockUpdateId: Scalars['Float'];
}>;


export type ContentBlockUpdateMutation = { __typename?: 'Mutation', contentBlockUpdate: { __typename?: 'ContentBlock', content?: string | null, id: number, type: string } };

export type ContentBlockRemoveMutationVariables = Exact<{
  contentBlockRemoveId: Scalars['Float'];
}>;


export type ContentBlockRemoveMutation = { __typename?: 'Mutation', contentBlockRemove: { __typename?: 'ContentBlock', id: number } };

export type MediaBlockFragmentFragment = { __typename?: 'MediaBlock', fileLink: string, id: number, label?: string | null, type: string };

export type MediaBlockCreateMutationVariables = Exact<{
  body: CreateMediaBlockInput;
}>;


export type MediaBlockCreateMutation = { __typename?: 'Mutation', mediaBlockCreate: { __typename?: 'MediaBlock', fileLink: string, id: number, label?: string | null, type: string } };

export type MediaBlockUpdateMutationVariables = Exact<{
  body: UpdateMediaBlockInput;
  mediaBlockUpdateId: Scalars['Float'];
}>;


export type MediaBlockUpdateMutation = { __typename?: 'Mutation', mediaBlockUpdate: { __typename?: 'MediaBlock', fileLink: string, id: number, label?: string | null, type: string } };

export type MediaBlockRemoveMutationVariables = Exact<{
  mediaBlockRemoveId: Scalars['Float'];
}>;


export type MediaBlockRemoveMutation = { __typename?: 'Mutation', mediaBlockRemove: { __typename?: 'MediaBlock', id: number } };

export type SelectionFindAllForAdminQueryVariables = Exact<{
  entityQuery: EntityQuery;
}>;


export type SelectionFindAllForAdminQuery = { __typename?: 'Query', selectionFindAllForAdmin: { __typename?: 'EntityPagination', count: number, data: Array<{ __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock, industry?: Array<{ __typename?: 'Industry', id: string }> | null, books?: Array<{ __typename?: 'BookEntityWithRelasion', uuid: string }> | null }> } };

export type SelectionFindOneQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SelectionFindOneQuery = { __typename?: 'Query', selectionFindOne: { __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock, industry?: Array<{ __typename?: 'Industry', id: string }> | null, books?: Array<{ __typename?: 'BookEntityWithRelasion', annotation?: string | null, book: string, counterPages?: number | null, image?: string | null, legalPossibilitiesUse?: string | null, placePublication?: string | null, title: string, uuid: string, yearPublisher?: number | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> | null, audios?: Array<{ __typename?: 'AudioEntity', uuid: string, title: string, image?: string | null, audio: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> | null, videos?: Array<{ __typename?: 'VideoEntity', uuid: string, title: string, image?: string | null, video: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> | null } };

export type PodcastFindAllForAdminQueryVariables = Exact<{
  entityQuery: EntityQuery;
}>;


export type PodcastFindAllForAdminQuery = { __typename?: 'Query', podcastFindAllForAdmin: { __typename?: 'EntityPagination', count: number, data: Array<{ __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock, industry?: Array<{ __typename?: 'Industry', id: string }> | null, audios?: Array<{ __typename?: 'AudioEntity', uuid: string }> | null, videos?: Array<{ __typename?: 'VideoEntity', uuid: string }> | null }> } };

export type PodcastFindOneQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type PodcastFindOneQuery = { __typename?: 'Query', podcastFindOne: { __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock, industry?: Array<{ __typename?: 'Industry', id: string }> | null, audios?: Array<{ __typename?: 'AudioEntity', uuid: string, title: string, image?: string | null, audio: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> | null, videos?: Array<{ __typename?: 'VideoEntity', uuid: string, title: string, image?: string | null, video: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> | null } };

export type EntityFragmentFragment = { __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock };

export type EntityIndustryFragmentFragment = { __typename?: 'Industry', id: string };

export type EntityCreateMutationVariables = Exact<{
  body: CreateEntityInput;
}>;


export type EntityCreateMutation = { __typename?: 'Mutation', entityCreate: { __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock } };

export type EntityUpdateMutationVariables = Exact<{
  body: UpdateEntityInput;
  uuid: Scalars['String'];
}>;


export type EntityUpdateMutation = { __typename?: 'Mutation', entityUpdate: { __typename?: 'Entity', uuid: string, title: string, slug?: string | null, image?: string | null, isPublish: boolean, annotation?: string | null, type: TypeEntity, typeBlock: TypeBlock } };

export type EntityRemoveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type EntityRemoveMutation = { __typename?: 'Mutation', entityRemove: { __typename?: 'Entity', uuid: string } };

export type FeedbackFragmentFragment = { __typename?: 'Feedback', email: string, name: string, text: string, id: number };

export type FeedbackDeleteMutationVariables = Exact<{
  feedbackDeleteId: Scalars['Float'];
}>;


export type FeedbackDeleteMutation = { __typename?: 'Mutation', feedbackDelete: { __typename?: 'Feedback', id: number } };

export type FeedbackFindallQueryVariables = Exact<{ [key: string]: never; }>;


export type FeedbackFindallQuery = { __typename?: 'Query', feedbackFindall: Array<{ __typename?: 'Feedback', email: string, name: string, text: string, id: number }> };

export type FeedbackFindOneQueryVariables = Exact<{
  feedbackFindOneId: Scalars['Float'];
}>;


export type FeedbackFindOneQuery = { __typename?: 'Query', feedbackFindOne: { __typename?: 'Feedback', email: string, name: string, text: string, id: number } };

export type IndustryFragmentFragment = { __typename?: 'Industry', color: string, content: string, description: string, id: string, isMain: boolean, order: number, photos: string, title: string };

export type IndustryCreateMutationVariables = Exact<{
  body: CreateIndustryInput;
}>;


export type IndustryCreateMutation = { __typename?: 'Mutation', industryCreate: { __typename?: 'Industry', color: string, content: string, description: string, id: string, isMain: boolean, order: number, photos: string, title: string } };

export type IndustryRemoveMutationVariables = Exact<{
  industryRemoveId: Scalars['Int'];
}>;


export type IndustryRemoveMutation = { __typename?: 'Mutation', industryRemove: { __typename?: 'Industry', color: string, content: string, description: string, id: string, isMain: boolean, order: number, photos: string, title: string } };

export type IndustryUpdateMutationVariables = Exact<{
  body: UpdateIndustryInput;
  industryUpdateId: Scalars['Float'];
}>;


export type IndustryUpdateMutation = { __typename?: 'Mutation', industryUpdate: { __typename?: 'Industry', color: string, content: string, description: string, id: string, isMain: boolean, order: number, photos: string, title: string } };

export type IndustryFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type IndustryFindAllQuery = { __typename?: 'Query', industryFindAll: Array<{ __typename?: 'Industry', color: string, content: string, description: string, id: string, isMain: boolean, order: number, photos: string, title: string }> };

export type IndustryFindOneQueryVariables = Exact<{
  industryFindOneId: Scalars['Int'];
}>;


export type IndustryFindOneQuery = { __typename?: 'Query', industryFindOne: { __typename?: 'Industry', color: string, content: string, description: string, id: string, isMain: boolean, order: number, photos: string, title: string } };

export type VideoFragmentFragment = { __typename?: 'VideoEntity', uuid: string, title: string, image?: string | null, video: string, annotation?: string | null, duration?: string | null };

export type VideoCreateMutationVariables = Exact<{
  body: CreateVideoEntityInput;
}>;


export type VideoCreateMutation = { __typename?: 'Mutation', videoCreate: { __typename?: 'VideoEntity', uuid: string, title: string, image?: string | null, video: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null } };

export type VideoUpdateMutationVariables = Exact<{
  body: UpdateVideoEntityInput;
  uuid: Scalars['String'];
}>;


export type VideoUpdateMutation = { __typename?: 'Mutation', videoUpdate: { __typename?: 'VideoEntity', uuid: string, title: string, image?: string | null, video: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null } };

export type VideoRemoveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type VideoRemoveMutation = { __typename?: 'Mutation', videoRemove: { __typename?: 'VideoEntity', uuid: string } };

export type VideoFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type VideoFindAllQuery = { __typename?: 'Query', videoFindAll: Array<{ __typename?: 'VideoEntity', uuid: string, title: string, image?: string | null, video: string, annotation?: string | null, duration?: string | null, authors?: Array<{ __typename?: 'Author', patronymic?: string | null, lastName: string, firstName?: string | null, deathday?: string | null, birthday?: string | null, id: string }> | null }> };

export const ArticleFragmentFragmentDoc = gql`
    fragment ArticleFragment on Article {
  id
  title
  slug
  preview
  isActive
  annotation
  createdAt
}
    `;
export const ArticleBlockFragmentFragmentDoc = gql`
    fragment ArticleBlockFragment on ArticleBlock {
  id
  order
  articleId
  contentBlockId
}
    `;
export const AudioFragmentFragmentDoc = gql`
    fragment AudioFragment on AudioEntity {
  uuid
  title
  image
  audio
  annotation
  duration
}
    `;
export const UserWithTokenFragmentFragmentDoc = gql`
    fragment UserWithTokenFragment on UserWithToken {
  email
  isBanned
  isEmailConfirmation
  name
  role
}
    `;
export const AuthorFragmentFragmentDoc = gql`
    fragment AuthorFragment on Author {
  patronymic
  lastName
  firstName
  deathday
  birthday
  id
}
    `;
export const BookFragmentFragmentDoc = gql`
    fragment BookFragment on BookEntityWithRelasion {
  annotation
  book
  counterPages
  image
  legalPossibilitiesUse
  placePublication
  title
  uuid
  yearPublisher
}
    `;
export const ContentBlockFragmentFragmentDoc = gql`
    fragment ContentBlockFragment on ContentBlock {
  content
  id
  type
}
    `;
export const MediaBlockFragmentFragmentDoc = gql`
    fragment MediaBlockFragment on MediaBlock {
  fileLink
  id
  label
  type
}
    `;
export const EntityFragmentFragmentDoc = gql`
    fragment EntityFragment on Entity {
  uuid
  title
  slug
  image
  isPublish
  annotation
  type
  typeBlock
}
    `;
export const EntityIndustryFragmentFragmentDoc = gql`
    fragment EntityIndustryFragment on Industry {
  id
}
    `;
export const FeedbackFragmentFragmentDoc = gql`
    fragment FeedbackFragment on Feedback {
  email
  name
  text
  id
}
    `;
export const IndustryFragmentFragmentDoc = gql`
    fragment IndustryFragment on Industry {
  color
  content
  description
  id
  isMain
  order
  photos
  title
}
    `;
export const VideoFragmentFragmentDoc = gql`
    fragment VideoFragment on VideoEntity {
  uuid
  title
  image
  video
  annotation
  duration
}
    `;
export const ArticleCreateDocument = gql`
    mutation ArticleCreate($body: CreateArticleInput!) {
  articleCreate(body: $body) {
    ...ArticleFragment
  }
}
    ${ArticleFragmentFragmentDoc}`;
export type ArticleCreateMutationFn = Apollo.MutationFunction<ArticleCreateMutation, ArticleCreateMutationVariables>;

/**
 * __useArticleCreateMutation__
 *
 * To run a mutation, you first call `useArticleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCreateMutation, { data, loading, error }] = useArticleCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useArticleCreateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleCreateMutation, ArticleCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleCreateMutation, ArticleCreateMutationVariables>(ArticleCreateDocument, options);
      }
export type ArticleCreateMutationHookResult = ReturnType<typeof useArticleCreateMutation>;
export type ArticleCreateMutationResult = Apollo.MutationResult<ArticleCreateMutation>;
export type ArticleCreateMutationOptions = Apollo.BaseMutationOptions<ArticleCreateMutation, ArticleCreateMutationVariables>;
export const ArticleUpdateDocument = gql`
    mutation ArticleUpdate($body: UpdateArticleInput!, $articleUpdateId: Float!) {
  articleUpdate(body: $body, id: $articleUpdateId) {
    ...ArticleFragment
  }
}
    ${ArticleFragmentFragmentDoc}`;
export type ArticleUpdateMutationFn = Apollo.MutationFunction<ArticleUpdateMutation, ArticleUpdateMutationVariables>;

/**
 * __useArticleUpdateMutation__
 *
 * To run a mutation, you first call `useArticleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleUpdateMutation, { data, loading, error }] = useArticleUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      articleUpdateId: // value for 'articleUpdateId'
 *   },
 * });
 */
export function useArticleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleUpdateMutation, ArticleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleUpdateMutation, ArticleUpdateMutationVariables>(ArticleUpdateDocument, options);
      }
export type ArticleUpdateMutationHookResult = ReturnType<typeof useArticleUpdateMutation>;
export type ArticleUpdateMutationResult = Apollo.MutationResult<ArticleUpdateMutation>;
export type ArticleUpdateMutationOptions = Apollo.BaseMutationOptions<ArticleUpdateMutation, ArticleUpdateMutationVariables>;
export const ArticleRemoveDocument = gql`
    mutation ArticleRemove($articleRemoveId: Float!) {
  articleRemove(id: $articleRemoveId) {
    id
  }
}
    `;
export type ArticleRemoveMutationFn = Apollo.MutationFunction<ArticleRemoveMutation, ArticleRemoveMutationVariables>;

/**
 * __useArticleRemoveMutation__
 *
 * To run a mutation, you first call `useArticleRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleRemoveMutation, { data, loading, error }] = useArticleRemoveMutation({
 *   variables: {
 *      articleRemoveId: // value for 'articleRemoveId'
 *   },
 * });
 */
export function useArticleRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ArticleRemoveMutation, ArticleRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleRemoveMutation, ArticleRemoveMutationVariables>(ArticleRemoveDocument, options);
      }
export type ArticleRemoveMutationHookResult = ReturnType<typeof useArticleRemoveMutation>;
export type ArticleRemoveMutationResult = Apollo.MutationResult<ArticleRemoveMutation>;
export type ArticleRemoveMutationOptions = Apollo.BaseMutationOptions<ArticleRemoveMutation, ArticleRemoveMutationVariables>;
export const ArticleFindAllForAdminDocument = gql`
    query ArticleFindAllForAdmin($newsQuery: ArticleQuery!) {
  articleFindAllForAdmin(newsQuery: $newsQuery) {
    count
    data {
      ...ArticleFragment
      industry {
        ...EntityIndustryFragment
      }
    }
  }
}
    ${ArticleFragmentFragmentDoc}
${EntityIndustryFragmentFragmentDoc}`;

/**
 * __useArticleFindAllForAdminQuery__
 *
 * To run a query within a React component, call `useArticleFindAllForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleFindAllForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleFindAllForAdminQuery({
 *   variables: {
 *      newsQuery: // value for 'newsQuery'
 *   },
 * });
 */
export function useArticleFindAllForAdminQuery(baseOptions: Apollo.QueryHookOptions<ArticleFindAllForAdminQuery, ArticleFindAllForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleFindAllForAdminQuery, ArticleFindAllForAdminQueryVariables>(ArticleFindAllForAdminDocument, options);
      }
export function useArticleFindAllForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleFindAllForAdminQuery, ArticleFindAllForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleFindAllForAdminQuery, ArticleFindAllForAdminQueryVariables>(ArticleFindAllForAdminDocument, options);
        }
export type ArticleFindAllForAdminQueryHookResult = ReturnType<typeof useArticleFindAllForAdminQuery>;
export type ArticleFindAllForAdminLazyQueryHookResult = ReturnType<typeof useArticleFindAllForAdminLazyQuery>;
export type ArticleFindAllForAdminQueryResult = Apollo.QueryResult<ArticleFindAllForAdminQuery, ArticleFindAllForAdminQueryVariables>;
export const ArticleFindOneDocument = gql`
    query ArticleFindOne($articleFindOneId: Float!) {
  articleFindOne(id: $articleFindOneId) {
    ...ArticleFragment
    industry {
      ...EntityIndustryFragment
    }
    articleContents {
      ...ArticleBlockFragment
      contentBlocks {
        ...ContentBlockFragment
        mediaBlocks {
          ...MediaBlockFragment
        }
      }
    }
  }
}
    ${ArticleFragmentFragmentDoc}
${EntityIndustryFragmentFragmentDoc}
${ArticleBlockFragmentFragmentDoc}
${ContentBlockFragmentFragmentDoc}
${MediaBlockFragmentFragmentDoc}`;

/**
 * __useArticleFindOneQuery__
 *
 * To run a query within a React component, call `useArticleFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleFindOneQuery({
 *   variables: {
 *      articleFindOneId: // value for 'articleFindOneId'
 *   },
 * });
 */
export function useArticleFindOneQuery(baseOptions: Apollo.QueryHookOptions<ArticleFindOneQuery, ArticleFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleFindOneQuery, ArticleFindOneQueryVariables>(ArticleFindOneDocument, options);
      }
export function useArticleFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleFindOneQuery, ArticleFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleFindOneQuery, ArticleFindOneQueryVariables>(ArticleFindOneDocument, options);
        }
export type ArticleFindOneQueryHookResult = ReturnType<typeof useArticleFindOneQuery>;
export type ArticleFindOneLazyQueryHookResult = ReturnType<typeof useArticleFindOneLazyQuery>;
export type ArticleFindOneQueryResult = Apollo.QueryResult<ArticleFindOneQuery, ArticleFindOneQueryVariables>;
export const ArticleBlockCreateDocument = gql`
    mutation ArticleBlockCreate($body: CreateArticleBlockInput!) {
  articleBlockCreate(body: $body) {
    ...ArticleBlockFragment
    contentBlocks {
      ...ContentBlockFragment
      mediaBlocks {
        ...MediaBlockFragment
      }
    }
  }
}
    ${ArticleBlockFragmentFragmentDoc}
${ContentBlockFragmentFragmentDoc}
${MediaBlockFragmentFragmentDoc}`;
export type ArticleBlockCreateMutationFn = Apollo.MutationFunction<ArticleBlockCreateMutation, ArticleBlockCreateMutationVariables>;

/**
 * __useArticleBlockCreateMutation__
 *
 * To run a mutation, you first call `useArticleBlockCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleBlockCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleBlockCreateMutation, { data, loading, error }] = useArticleBlockCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useArticleBlockCreateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleBlockCreateMutation, ArticleBlockCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleBlockCreateMutation, ArticleBlockCreateMutationVariables>(ArticleBlockCreateDocument, options);
      }
export type ArticleBlockCreateMutationHookResult = ReturnType<typeof useArticleBlockCreateMutation>;
export type ArticleBlockCreateMutationResult = Apollo.MutationResult<ArticleBlockCreateMutation>;
export type ArticleBlockCreateMutationOptions = Apollo.BaseMutationOptions<ArticleBlockCreateMutation, ArticleBlockCreateMutationVariables>;
export const ArticleBlockUpdateDocument = gql`
    mutation ArticleBlockUpdate($body: UpdateArticleBlockInput!, $articleBlockUpdateId: Float!) {
  articleBlockUpdate(body: $body, id: $articleBlockUpdateId) {
    ...ArticleBlockFragment
    contentBlocks {
      ...ContentBlockFragment
      mediaBlocks {
        ...MediaBlockFragment
      }
    }
  }
}
    ${ArticleBlockFragmentFragmentDoc}
${ContentBlockFragmentFragmentDoc}
${MediaBlockFragmentFragmentDoc}`;
export type ArticleBlockUpdateMutationFn = Apollo.MutationFunction<ArticleBlockUpdateMutation, ArticleBlockUpdateMutationVariables>;

/**
 * __useArticleBlockUpdateMutation__
 *
 * To run a mutation, you first call `useArticleBlockUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleBlockUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleBlockUpdateMutation, { data, loading, error }] = useArticleBlockUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      articleBlockUpdateId: // value for 'articleBlockUpdateId'
 *   },
 * });
 */
export function useArticleBlockUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleBlockUpdateMutation, ArticleBlockUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleBlockUpdateMutation, ArticleBlockUpdateMutationVariables>(ArticleBlockUpdateDocument, options);
      }
export type ArticleBlockUpdateMutationHookResult = ReturnType<typeof useArticleBlockUpdateMutation>;
export type ArticleBlockUpdateMutationResult = Apollo.MutationResult<ArticleBlockUpdateMutation>;
export type ArticleBlockUpdateMutationOptions = Apollo.BaseMutationOptions<ArticleBlockUpdateMutation, ArticleBlockUpdateMutationVariables>;
export const ArticleBlockRemoveDocument = gql`
    mutation ArticleBlockRemove($articleBlockRemoveId: Float!) {
  articleBlockRemove(id: $articleBlockRemoveId) {
    id
  }
}
    `;
export type ArticleBlockRemoveMutationFn = Apollo.MutationFunction<ArticleBlockRemoveMutation, ArticleBlockRemoveMutationVariables>;

/**
 * __useArticleBlockRemoveMutation__
 *
 * To run a mutation, you first call `useArticleBlockRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleBlockRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleBlockRemoveMutation, { data, loading, error }] = useArticleBlockRemoveMutation({
 *   variables: {
 *      articleBlockRemoveId: // value for 'articleBlockRemoveId'
 *   },
 * });
 */
export function useArticleBlockRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ArticleBlockRemoveMutation, ArticleBlockRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleBlockRemoveMutation, ArticleBlockRemoveMutationVariables>(ArticleBlockRemoveDocument, options);
      }
export type ArticleBlockRemoveMutationHookResult = ReturnType<typeof useArticleBlockRemoveMutation>;
export type ArticleBlockRemoveMutationResult = Apollo.MutationResult<ArticleBlockRemoveMutation>;
export type ArticleBlockRemoveMutationOptions = Apollo.BaseMutationOptions<ArticleBlockRemoveMutation, ArticleBlockRemoveMutationVariables>;
export const AudioCreateDocument = gql`
    mutation AudioCreate($body: CreateAudioEntityInput!) {
  audioCreate(body: $body) {
    ...AudioFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${AudioFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;
export type AudioCreateMutationFn = Apollo.MutationFunction<AudioCreateMutation, AudioCreateMutationVariables>;

/**
 * __useAudioCreateMutation__
 *
 * To run a mutation, you first call `useAudioCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAudioCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [audioCreateMutation, { data, loading, error }] = useAudioCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAudioCreateMutation(baseOptions?: Apollo.MutationHookOptions<AudioCreateMutation, AudioCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AudioCreateMutation, AudioCreateMutationVariables>(AudioCreateDocument, options);
      }
export type AudioCreateMutationHookResult = ReturnType<typeof useAudioCreateMutation>;
export type AudioCreateMutationResult = Apollo.MutationResult<AudioCreateMutation>;
export type AudioCreateMutationOptions = Apollo.BaseMutationOptions<AudioCreateMutation, AudioCreateMutationVariables>;
export const AudioUpdateDocument = gql`
    mutation AudioUpdate($body: UpdateAudioEntityInput!, $uuid: String!) {
  audioUpdate(body: $body, uuid: $uuid) {
    ...AudioFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${AudioFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;
export type AudioUpdateMutationFn = Apollo.MutationFunction<AudioUpdateMutation, AudioUpdateMutationVariables>;

/**
 * __useAudioUpdateMutation__
 *
 * To run a mutation, you first call `useAudioUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAudioUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [audioUpdateMutation, { data, loading, error }] = useAudioUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAudioUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AudioUpdateMutation, AudioUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AudioUpdateMutation, AudioUpdateMutationVariables>(AudioUpdateDocument, options);
      }
export type AudioUpdateMutationHookResult = ReturnType<typeof useAudioUpdateMutation>;
export type AudioUpdateMutationResult = Apollo.MutationResult<AudioUpdateMutation>;
export type AudioUpdateMutationOptions = Apollo.BaseMutationOptions<AudioUpdateMutation, AudioUpdateMutationVariables>;
export const AudioRemoveDocument = gql`
    mutation AudioRemove($uuid: String!) {
  audioRemove(uuid: $uuid) {
    uuid
  }
}
    `;
export type AudioRemoveMutationFn = Apollo.MutationFunction<AudioRemoveMutation, AudioRemoveMutationVariables>;

/**
 * __useAudioRemoveMutation__
 *
 * To run a mutation, you first call `useAudioRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAudioRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [audioRemoveMutation, { data, loading, error }] = useAudioRemoveMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAudioRemoveMutation(baseOptions?: Apollo.MutationHookOptions<AudioRemoveMutation, AudioRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AudioRemoveMutation, AudioRemoveMutationVariables>(AudioRemoveDocument, options);
      }
export type AudioRemoveMutationHookResult = ReturnType<typeof useAudioRemoveMutation>;
export type AudioRemoveMutationResult = Apollo.MutationResult<AudioRemoveMutation>;
export type AudioRemoveMutationOptions = Apollo.BaseMutationOptions<AudioRemoveMutation, AudioRemoveMutationVariables>;
export const AudioFindAllDocument = gql`
    query AudioFindAll {
  audioFindAll {
    ...AudioFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${AudioFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;

/**
 * __useAudioFindAllQuery__
 *
 * To run a query within a React component, call `useAudioFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudioFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudioFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useAudioFindAllQuery(baseOptions?: Apollo.QueryHookOptions<AudioFindAllQuery, AudioFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudioFindAllQuery, AudioFindAllQueryVariables>(AudioFindAllDocument, options);
      }
export function useAudioFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudioFindAllQuery, AudioFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudioFindAllQuery, AudioFindAllQueryVariables>(AudioFindAllDocument, options);
        }
export type AudioFindAllQueryHookResult = ReturnType<typeof useAudioFindAllQuery>;
export type AudioFindAllLazyQueryHookResult = ReturnType<typeof useAudioFindAllLazyQuery>;
export type AudioFindAllQueryResult = Apollo.QueryResult<AudioFindAllQuery, AudioFindAllQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const SigninLocalDocument = gql`
    mutation SigninLocal($body: AuthorizationDto!) {
  signinLocal(body: $body) {
    id
    accessToken
    refreshToken
    ...UserWithTokenFragment
  }
}
    ${UserWithTokenFragmentFragmentDoc}`;
export type SigninLocalMutationFn = Apollo.MutationFunction<SigninLocalMutation, SigninLocalMutationVariables>;

/**
 * __useSigninLocalMutation__
 *
 * To run a mutation, you first call `useSigninLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinLocalMutation, { data, loading, error }] = useSigninLocalMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSigninLocalMutation(baseOptions?: Apollo.MutationHookOptions<SigninLocalMutation, SigninLocalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninLocalMutation, SigninLocalMutationVariables>(SigninLocalDocument, options);
      }
export type SigninLocalMutationHookResult = ReturnType<typeof useSigninLocalMutation>;
export type SigninLocalMutationResult = Apollo.MutationResult<SigninLocalMutation>;
export type SigninLocalMutationOptions = Apollo.BaseMutationOptions<SigninLocalMutation, SigninLocalMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    email
    id
    isBanned
    isEmailConfirmation
    name
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AuthorCreateDocument = gql`
    mutation AuthorCreate($body: CreateAuthorInput!) {
  authorCreate(body: $body) {
    ...AuthorFragment
  }
}
    ${AuthorFragmentFragmentDoc}`;
export type AuthorCreateMutationFn = Apollo.MutationFunction<AuthorCreateMutation, AuthorCreateMutationVariables>;

/**
 * __useAuthorCreateMutation__
 *
 * To run a mutation, you first call `useAuthorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorCreateMutation, { data, loading, error }] = useAuthorCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAuthorCreateMutation(baseOptions?: Apollo.MutationHookOptions<AuthorCreateMutation, AuthorCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorCreateMutation, AuthorCreateMutationVariables>(AuthorCreateDocument, options);
      }
export type AuthorCreateMutationHookResult = ReturnType<typeof useAuthorCreateMutation>;
export type AuthorCreateMutationResult = Apollo.MutationResult<AuthorCreateMutation>;
export type AuthorCreateMutationOptions = Apollo.BaseMutationOptions<AuthorCreateMutation, AuthorCreateMutationVariables>;
export const AuthorUpdateDocument = gql`
    mutation AuthorUpdate($body: UpdateAuthorInput!, $authorUpdateId: Float!) {
  authorUpdate(body: $body, id: $authorUpdateId) {
    ...AuthorFragment
  }
}
    ${AuthorFragmentFragmentDoc}`;
export type AuthorUpdateMutationFn = Apollo.MutationFunction<AuthorUpdateMutation, AuthorUpdateMutationVariables>;

/**
 * __useAuthorUpdateMutation__
 *
 * To run a mutation, you first call `useAuthorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorUpdateMutation, { data, loading, error }] = useAuthorUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      authorUpdateId: // value for 'authorUpdateId'
 *   },
 * });
 */
export function useAuthorUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AuthorUpdateMutation, AuthorUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorUpdateMutation, AuthorUpdateMutationVariables>(AuthorUpdateDocument, options);
      }
export type AuthorUpdateMutationHookResult = ReturnType<typeof useAuthorUpdateMutation>;
export type AuthorUpdateMutationResult = Apollo.MutationResult<AuthorUpdateMutation>;
export type AuthorUpdateMutationOptions = Apollo.BaseMutationOptions<AuthorUpdateMutation, AuthorUpdateMutationVariables>;
export const AuthorRemoveDocument = gql`
    mutation AuthorRemove($authorRemoveId: Int!) {
  authorRemove(id: $authorRemoveId) {
    id
  }
}
    `;
export type AuthorRemoveMutationFn = Apollo.MutationFunction<AuthorRemoveMutation, AuthorRemoveMutationVariables>;

/**
 * __useAuthorRemoveMutation__
 *
 * To run a mutation, you first call `useAuthorRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorRemoveMutation, { data, loading, error }] = useAuthorRemoveMutation({
 *   variables: {
 *      authorRemoveId: // value for 'authorRemoveId'
 *   },
 * });
 */
export function useAuthorRemoveMutation(baseOptions?: Apollo.MutationHookOptions<AuthorRemoveMutation, AuthorRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorRemoveMutation, AuthorRemoveMutationVariables>(AuthorRemoveDocument, options);
      }
export type AuthorRemoveMutationHookResult = ReturnType<typeof useAuthorRemoveMutation>;
export type AuthorRemoveMutationResult = Apollo.MutationResult<AuthorRemoveMutation>;
export type AuthorRemoveMutationOptions = Apollo.BaseMutationOptions<AuthorRemoveMutation, AuthorRemoveMutationVariables>;
export const AuthorFindAllDocument = gql`
    query AuthorFindAll {
  authorFindAll {
    ...AuthorFragment
  }
}
    ${AuthorFragmentFragmentDoc}`;

/**
 * __useAuthorFindAllQuery__
 *
 * To run a query within a React component, call `useAuthorFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorFindAllQuery(baseOptions?: Apollo.QueryHookOptions<AuthorFindAllQuery, AuthorFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorFindAllQuery, AuthorFindAllQueryVariables>(AuthorFindAllDocument, options);
      }
export function useAuthorFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorFindAllQuery, AuthorFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorFindAllQuery, AuthorFindAllQueryVariables>(AuthorFindAllDocument, options);
        }
export type AuthorFindAllQueryHookResult = ReturnType<typeof useAuthorFindAllQuery>;
export type AuthorFindAllLazyQueryHookResult = ReturnType<typeof useAuthorFindAllLazyQuery>;
export type AuthorFindAllQueryResult = Apollo.QueryResult<AuthorFindAllQuery, AuthorFindAllQueryVariables>;
export const BookCreateDocument = gql`
    mutation BookCreate($body: CreateBookEntityInput!) {
  bookCreate(body: $body) {
    ...BookFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${BookFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;
export type BookCreateMutationFn = Apollo.MutationFunction<BookCreateMutation, BookCreateMutationVariables>;

/**
 * __useBookCreateMutation__
 *
 * To run a mutation, you first call `useBookCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookCreateMutation, { data, loading, error }] = useBookCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useBookCreateMutation(baseOptions?: Apollo.MutationHookOptions<BookCreateMutation, BookCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookCreateMutation, BookCreateMutationVariables>(BookCreateDocument, options);
      }
export type BookCreateMutationHookResult = ReturnType<typeof useBookCreateMutation>;
export type BookCreateMutationResult = Apollo.MutationResult<BookCreateMutation>;
export type BookCreateMutationOptions = Apollo.BaseMutationOptions<BookCreateMutation, BookCreateMutationVariables>;
export const BookUpdateDocument = gql`
    mutation BookUpdate($body: UpdateBookEntityInput!, $uuid: String!) {
  bookUpdate(body: $body, uuid: $uuid) {
    ...BookFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${BookFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;
export type BookUpdateMutationFn = Apollo.MutationFunction<BookUpdateMutation, BookUpdateMutationVariables>;

/**
 * __useBookUpdateMutation__
 *
 * To run a mutation, you first call `useBookUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookUpdateMutation, { data, loading, error }] = useBookUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBookUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BookUpdateMutation, BookUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookUpdateMutation, BookUpdateMutationVariables>(BookUpdateDocument, options);
      }
export type BookUpdateMutationHookResult = ReturnType<typeof useBookUpdateMutation>;
export type BookUpdateMutationResult = Apollo.MutationResult<BookUpdateMutation>;
export type BookUpdateMutationOptions = Apollo.BaseMutationOptions<BookUpdateMutation, BookUpdateMutationVariables>;
export const BookRemoveDocument = gql`
    mutation BookRemove($uuid: String!) {
  bookRemove(uuid: $uuid) {
    uuid
  }
}
    `;
export type BookRemoveMutationFn = Apollo.MutationFunction<BookRemoveMutation, BookRemoveMutationVariables>;

/**
 * __useBookRemoveMutation__
 *
 * To run a mutation, you first call `useBookRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookRemoveMutation, { data, loading, error }] = useBookRemoveMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBookRemoveMutation(baseOptions?: Apollo.MutationHookOptions<BookRemoveMutation, BookRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookRemoveMutation, BookRemoveMutationVariables>(BookRemoveDocument, options);
      }
export type BookRemoveMutationHookResult = ReturnType<typeof useBookRemoveMutation>;
export type BookRemoveMutationResult = Apollo.MutationResult<BookRemoveMutation>;
export type BookRemoveMutationOptions = Apollo.BaseMutationOptions<BookRemoveMutation, BookRemoveMutationVariables>;
export const BookFindAllDocument = gql`
    query BookFindAll {
  bookFindAll {
    ...BookFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${BookFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;

/**
 * __useBookFindAllQuery__
 *
 * To run a query within a React component, call `useBookFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookFindAllQuery(baseOptions?: Apollo.QueryHookOptions<BookFindAllQuery, BookFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookFindAllQuery, BookFindAllQueryVariables>(BookFindAllDocument, options);
      }
export function useBookFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookFindAllQuery, BookFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookFindAllQuery, BookFindAllQueryVariables>(BookFindAllDocument, options);
        }
export type BookFindAllQueryHookResult = ReturnType<typeof useBookFindAllQuery>;
export type BookFindAllLazyQueryHookResult = ReturnType<typeof useBookFindAllLazyQuery>;
export type BookFindAllQueryResult = Apollo.QueryResult<BookFindAllQuery, BookFindAllQueryVariables>;
export const BookFindAllLiteDocument = gql`
    query BookFindAllLite {
  bookFindAll {
    title
    book
    authors {
      lastName
      patronymic
      firstName
      id
    }
  }
}
    `;

/**
 * __useBookFindAllLiteQuery__
 *
 * To run a query within a React component, call `useBookFindAllLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookFindAllLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookFindAllLiteQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookFindAllLiteQuery(baseOptions?: Apollo.QueryHookOptions<BookFindAllLiteQuery, BookFindAllLiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookFindAllLiteQuery, BookFindAllLiteQueryVariables>(BookFindAllLiteDocument, options);
      }
export function useBookFindAllLiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookFindAllLiteQuery, BookFindAllLiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookFindAllLiteQuery, BookFindAllLiteQueryVariables>(BookFindAllLiteDocument, options);
        }
export type BookFindAllLiteQueryHookResult = ReturnType<typeof useBookFindAllLiteQuery>;
export type BookFindAllLiteLazyQueryHookResult = ReturnType<typeof useBookFindAllLiteLazyQuery>;
export type BookFindAllLiteQueryResult = Apollo.QueryResult<BookFindAllLiteQuery, BookFindAllLiteQueryVariables>;
export const ContentBlockCreateDocument = gql`
    mutation ContentBlockCreate($body: CreateContentBlockInput!) {
  contentBlockCreate(body: $body) {
    ...ContentBlockFragment
  }
}
    ${ContentBlockFragmentFragmentDoc}`;
export type ContentBlockCreateMutationFn = Apollo.MutationFunction<ContentBlockCreateMutation, ContentBlockCreateMutationVariables>;

/**
 * __useContentBlockCreateMutation__
 *
 * To run a mutation, you first call `useContentBlockCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentBlockCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentBlockCreateMutation, { data, loading, error }] = useContentBlockCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useContentBlockCreateMutation(baseOptions?: Apollo.MutationHookOptions<ContentBlockCreateMutation, ContentBlockCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContentBlockCreateMutation, ContentBlockCreateMutationVariables>(ContentBlockCreateDocument, options);
      }
export type ContentBlockCreateMutationHookResult = ReturnType<typeof useContentBlockCreateMutation>;
export type ContentBlockCreateMutationResult = Apollo.MutationResult<ContentBlockCreateMutation>;
export type ContentBlockCreateMutationOptions = Apollo.BaseMutationOptions<ContentBlockCreateMutation, ContentBlockCreateMutationVariables>;
export const ContentBlockUpdateDocument = gql`
    mutation ContentBlockUpdate($body: UpdateContentBlockInput!, $contentBlockUpdateId: Float!) {
  contentBlockUpdate(body: $body, id: $contentBlockUpdateId) {
    ...ContentBlockFragment
  }
}
    ${ContentBlockFragmentFragmentDoc}`;
export type ContentBlockUpdateMutationFn = Apollo.MutationFunction<ContentBlockUpdateMutation, ContentBlockUpdateMutationVariables>;

/**
 * __useContentBlockUpdateMutation__
 *
 * To run a mutation, you first call `useContentBlockUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentBlockUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentBlockUpdateMutation, { data, loading, error }] = useContentBlockUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      contentBlockUpdateId: // value for 'contentBlockUpdateId'
 *   },
 * });
 */
export function useContentBlockUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ContentBlockUpdateMutation, ContentBlockUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContentBlockUpdateMutation, ContentBlockUpdateMutationVariables>(ContentBlockUpdateDocument, options);
      }
export type ContentBlockUpdateMutationHookResult = ReturnType<typeof useContentBlockUpdateMutation>;
export type ContentBlockUpdateMutationResult = Apollo.MutationResult<ContentBlockUpdateMutation>;
export type ContentBlockUpdateMutationOptions = Apollo.BaseMutationOptions<ContentBlockUpdateMutation, ContentBlockUpdateMutationVariables>;
export const ContentBlockRemoveDocument = gql`
    mutation ContentBlockRemove($contentBlockRemoveId: Float!) {
  contentBlockRemove(id: $contentBlockRemoveId) {
    id
  }
}
    `;
export type ContentBlockRemoveMutationFn = Apollo.MutationFunction<ContentBlockRemoveMutation, ContentBlockRemoveMutationVariables>;

/**
 * __useContentBlockRemoveMutation__
 *
 * To run a mutation, you first call `useContentBlockRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentBlockRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentBlockRemoveMutation, { data, loading, error }] = useContentBlockRemoveMutation({
 *   variables: {
 *      contentBlockRemoveId: // value for 'contentBlockRemoveId'
 *   },
 * });
 */
export function useContentBlockRemoveMutation(baseOptions?: Apollo.MutationHookOptions<ContentBlockRemoveMutation, ContentBlockRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContentBlockRemoveMutation, ContentBlockRemoveMutationVariables>(ContentBlockRemoveDocument, options);
      }
export type ContentBlockRemoveMutationHookResult = ReturnType<typeof useContentBlockRemoveMutation>;
export type ContentBlockRemoveMutationResult = Apollo.MutationResult<ContentBlockRemoveMutation>;
export type ContentBlockRemoveMutationOptions = Apollo.BaseMutationOptions<ContentBlockRemoveMutation, ContentBlockRemoveMutationVariables>;
export const MediaBlockCreateDocument = gql`
    mutation MediaBlockCreate($body: CreateMediaBlockInput!) {
  mediaBlockCreate(body: $body) {
    ...MediaBlockFragment
  }
}
    ${MediaBlockFragmentFragmentDoc}`;
export type MediaBlockCreateMutationFn = Apollo.MutationFunction<MediaBlockCreateMutation, MediaBlockCreateMutationVariables>;

/**
 * __useMediaBlockCreateMutation__
 *
 * To run a mutation, you first call `useMediaBlockCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaBlockCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaBlockCreateMutation, { data, loading, error }] = useMediaBlockCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useMediaBlockCreateMutation(baseOptions?: Apollo.MutationHookOptions<MediaBlockCreateMutation, MediaBlockCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MediaBlockCreateMutation, MediaBlockCreateMutationVariables>(MediaBlockCreateDocument, options);
      }
export type MediaBlockCreateMutationHookResult = ReturnType<typeof useMediaBlockCreateMutation>;
export type MediaBlockCreateMutationResult = Apollo.MutationResult<MediaBlockCreateMutation>;
export type MediaBlockCreateMutationOptions = Apollo.BaseMutationOptions<MediaBlockCreateMutation, MediaBlockCreateMutationVariables>;
export const MediaBlockUpdateDocument = gql`
    mutation MediaBlockUpdate($body: UpdateMediaBlockInput!, $mediaBlockUpdateId: Float!) {
  mediaBlockUpdate(body: $body, id: $mediaBlockUpdateId) {
    ...MediaBlockFragment
  }
}
    ${MediaBlockFragmentFragmentDoc}`;
export type MediaBlockUpdateMutationFn = Apollo.MutationFunction<MediaBlockUpdateMutation, MediaBlockUpdateMutationVariables>;

/**
 * __useMediaBlockUpdateMutation__
 *
 * To run a mutation, you first call `useMediaBlockUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaBlockUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaBlockUpdateMutation, { data, loading, error }] = useMediaBlockUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      mediaBlockUpdateId: // value for 'mediaBlockUpdateId'
 *   },
 * });
 */
export function useMediaBlockUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MediaBlockUpdateMutation, MediaBlockUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MediaBlockUpdateMutation, MediaBlockUpdateMutationVariables>(MediaBlockUpdateDocument, options);
      }
export type MediaBlockUpdateMutationHookResult = ReturnType<typeof useMediaBlockUpdateMutation>;
export type MediaBlockUpdateMutationResult = Apollo.MutationResult<MediaBlockUpdateMutation>;
export type MediaBlockUpdateMutationOptions = Apollo.BaseMutationOptions<MediaBlockUpdateMutation, MediaBlockUpdateMutationVariables>;
export const MediaBlockRemoveDocument = gql`
    mutation MediaBlockRemove($mediaBlockRemoveId: Float!) {
  mediaBlockRemove(id: $mediaBlockRemoveId) {
    id
  }
}
    `;
export type MediaBlockRemoveMutationFn = Apollo.MutationFunction<MediaBlockRemoveMutation, MediaBlockRemoveMutationVariables>;

/**
 * __useMediaBlockRemoveMutation__
 *
 * To run a mutation, you first call `useMediaBlockRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaBlockRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaBlockRemoveMutation, { data, loading, error }] = useMediaBlockRemoveMutation({
 *   variables: {
 *      mediaBlockRemoveId: // value for 'mediaBlockRemoveId'
 *   },
 * });
 */
export function useMediaBlockRemoveMutation(baseOptions?: Apollo.MutationHookOptions<MediaBlockRemoveMutation, MediaBlockRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MediaBlockRemoveMutation, MediaBlockRemoveMutationVariables>(MediaBlockRemoveDocument, options);
      }
export type MediaBlockRemoveMutationHookResult = ReturnType<typeof useMediaBlockRemoveMutation>;
export type MediaBlockRemoveMutationResult = Apollo.MutationResult<MediaBlockRemoveMutation>;
export type MediaBlockRemoveMutationOptions = Apollo.BaseMutationOptions<MediaBlockRemoveMutation, MediaBlockRemoveMutationVariables>;
export const SelectionFindAllForAdminDocument = gql`
    query SelectionFindAllForAdmin($entityQuery: EntityQuery!) {
  selectionFindAllForAdmin(entityQuery: $entityQuery) {
    count
    data {
      ...EntityFragment
      industry {
        ...EntityIndustryFragment
      }
      books {
        uuid
      }
    }
  }
}
    ${EntityFragmentFragmentDoc}
${EntityIndustryFragmentFragmentDoc}`;

/**
 * __useSelectionFindAllForAdminQuery__
 *
 * To run a query within a React component, call `useSelectionFindAllForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectionFindAllForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectionFindAllForAdminQuery({
 *   variables: {
 *      entityQuery: // value for 'entityQuery'
 *   },
 * });
 */
export function useSelectionFindAllForAdminQuery(baseOptions: Apollo.QueryHookOptions<SelectionFindAllForAdminQuery, SelectionFindAllForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectionFindAllForAdminQuery, SelectionFindAllForAdminQueryVariables>(SelectionFindAllForAdminDocument, options);
      }
export function useSelectionFindAllForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectionFindAllForAdminQuery, SelectionFindAllForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectionFindAllForAdminQuery, SelectionFindAllForAdminQueryVariables>(SelectionFindAllForAdminDocument, options);
        }
export type SelectionFindAllForAdminQueryHookResult = ReturnType<typeof useSelectionFindAllForAdminQuery>;
export type SelectionFindAllForAdminLazyQueryHookResult = ReturnType<typeof useSelectionFindAllForAdminLazyQuery>;
export type SelectionFindAllForAdminQueryResult = Apollo.QueryResult<SelectionFindAllForAdminQuery, SelectionFindAllForAdminQueryVariables>;
export const SelectionFindOneDocument = gql`
    query SelectionFindOne($uuid: String!) {
  selectionFindOne(uuid: $uuid) {
    ...EntityFragment
    industry {
      ...EntityIndustryFragment
    }
    books {
      ...BookFragment
      authors {
        ...AuthorFragment
      }
    }
    audios {
      ...AudioFragment
      authors {
        ...AuthorFragment
      }
    }
    videos {
      ...VideoFragment
      authors {
        ...AuthorFragment
      }
    }
  }
}
    ${EntityFragmentFragmentDoc}
${EntityIndustryFragmentFragmentDoc}
${BookFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}
${AudioFragmentFragmentDoc}
${VideoFragmentFragmentDoc}`;

/**
 * __useSelectionFindOneQuery__
 *
 * To run a query within a React component, call `useSelectionFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectionFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectionFindOneQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSelectionFindOneQuery(baseOptions: Apollo.QueryHookOptions<SelectionFindOneQuery, SelectionFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectionFindOneQuery, SelectionFindOneQueryVariables>(SelectionFindOneDocument, options);
      }
export function useSelectionFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectionFindOneQuery, SelectionFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectionFindOneQuery, SelectionFindOneQueryVariables>(SelectionFindOneDocument, options);
        }
export type SelectionFindOneQueryHookResult = ReturnType<typeof useSelectionFindOneQuery>;
export type SelectionFindOneLazyQueryHookResult = ReturnType<typeof useSelectionFindOneLazyQuery>;
export type SelectionFindOneQueryResult = Apollo.QueryResult<SelectionFindOneQuery, SelectionFindOneQueryVariables>;
export const PodcastFindAllForAdminDocument = gql`
    query PodcastFindAllForAdmin($entityQuery: EntityQuery!) {
  podcastFindAllForAdmin(entityQuery: $entityQuery) {
    count
    data {
      ...EntityFragment
      industry {
        ...EntityIndustryFragment
      }
      audios {
        uuid
      }
      videos {
        uuid
      }
    }
  }
}
    ${EntityFragmentFragmentDoc}
${EntityIndustryFragmentFragmentDoc}`;

/**
 * __usePodcastFindAllForAdminQuery__
 *
 * To run a query within a React component, call `usePodcastFindAllForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `usePodcastFindAllForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePodcastFindAllForAdminQuery({
 *   variables: {
 *      entityQuery: // value for 'entityQuery'
 *   },
 * });
 */
export function usePodcastFindAllForAdminQuery(baseOptions: Apollo.QueryHookOptions<PodcastFindAllForAdminQuery, PodcastFindAllForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PodcastFindAllForAdminQuery, PodcastFindAllForAdminQueryVariables>(PodcastFindAllForAdminDocument, options);
      }
export function usePodcastFindAllForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PodcastFindAllForAdminQuery, PodcastFindAllForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PodcastFindAllForAdminQuery, PodcastFindAllForAdminQueryVariables>(PodcastFindAllForAdminDocument, options);
        }
export type PodcastFindAllForAdminQueryHookResult = ReturnType<typeof usePodcastFindAllForAdminQuery>;
export type PodcastFindAllForAdminLazyQueryHookResult = ReturnType<typeof usePodcastFindAllForAdminLazyQuery>;
export type PodcastFindAllForAdminQueryResult = Apollo.QueryResult<PodcastFindAllForAdminQuery, PodcastFindAllForAdminQueryVariables>;
export const PodcastFindOneDocument = gql`
    query PodcastFindOne($uuid: String!) {
  podcastFindOne(uuid: $uuid) {
    ...EntityFragment
    industry {
      ...EntityIndustryFragment
    }
    audios {
      ...AudioFragment
      authors {
        ...AuthorFragment
      }
    }
    videos {
      ...VideoFragment
      authors {
        ...AuthorFragment
      }
    }
  }
}
    ${EntityFragmentFragmentDoc}
${EntityIndustryFragmentFragmentDoc}
${AudioFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}
${VideoFragmentFragmentDoc}`;

/**
 * __usePodcastFindOneQuery__
 *
 * To run a query within a React component, call `usePodcastFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `usePodcastFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePodcastFindOneQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePodcastFindOneQuery(baseOptions: Apollo.QueryHookOptions<PodcastFindOneQuery, PodcastFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PodcastFindOneQuery, PodcastFindOneQueryVariables>(PodcastFindOneDocument, options);
      }
export function usePodcastFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PodcastFindOneQuery, PodcastFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PodcastFindOneQuery, PodcastFindOneQueryVariables>(PodcastFindOneDocument, options);
        }
export type PodcastFindOneQueryHookResult = ReturnType<typeof usePodcastFindOneQuery>;
export type PodcastFindOneLazyQueryHookResult = ReturnType<typeof usePodcastFindOneLazyQuery>;
export type PodcastFindOneQueryResult = Apollo.QueryResult<PodcastFindOneQuery, PodcastFindOneQueryVariables>;
export const EntityCreateDocument = gql`
    mutation EntityCreate($body: CreateEntityInput!) {
  entityCreate(body: $body) {
    ...EntityFragment
  }
}
    ${EntityFragmentFragmentDoc}`;
export type EntityCreateMutationFn = Apollo.MutationFunction<EntityCreateMutation, EntityCreateMutationVariables>;

/**
 * __useEntityCreateMutation__
 *
 * To run a mutation, you first call `useEntityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entityCreateMutation, { data, loading, error }] = useEntityCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useEntityCreateMutation(baseOptions?: Apollo.MutationHookOptions<EntityCreateMutation, EntityCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EntityCreateMutation, EntityCreateMutationVariables>(EntityCreateDocument, options);
      }
export type EntityCreateMutationHookResult = ReturnType<typeof useEntityCreateMutation>;
export type EntityCreateMutationResult = Apollo.MutationResult<EntityCreateMutation>;
export type EntityCreateMutationOptions = Apollo.BaseMutationOptions<EntityCreateMutation, EntityCreateMutationVariables>;
export const EntityUpdateDocument = gql`
    mutation EntityUpdate($body: UpdateEntityInput!, $uuid: String!) {
  entityUpdate(body: $body, uuid: $uuid) {
    ...EntityFragment
  }
}
    ${EntityFragmentFragmentDoc}`;
export type EntityUpdateMutationFn = Apollo.MutationFunction<EntityUpdateMutation, EntityUpdateMutationVariables>;

/**
 * __useEntityUpdateMutation__
 *
 * To run a mutation, you first call `useEntityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entityUpdateMutation, { data, loading, error }] = useEntityUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEntityUpdateMutation(baseOptions?: Apollo.MutationHookOptions<EntityUpdateMutation, EntityUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EntityUpdateMutation, EntityUpdateMutationVariables>(EntityUpdateDocument, options);
      }
export type EntityUpdateMutationHookResult = ReturnType<typeof useEntityUpdateMutation>;
export type EntityUpdateMutationResult = Apollo.MutationResult<EntityUpdateMutation>;
export type EntityUpdateMutationOptions = Apollo.BaseMutationOptions<EntityUpdateMutation, EntityUpdateMutationVariables>;
export const EntityRemoveDocument = gql`
    mutation EntityRemove($uuid: String!) {
  entityRemove(uuid: $uuid) {
    uuid
  }
}
    `;
export type EntityRemoveMutationFn = Apollo.MutationFunction<EntityRemoveMutation, EntityRemoveMutationVariables>;

/**
 * __useEntityRemoveMutation__
 *
 * To run a mutation, you first call `useEntityRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntityRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entityRemoveMutation, { data, loading, error }] = useEntityRemoveMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEntityRemoveMutation(baseOptions?: Apollo.MutationHookOptions<EntityRemoveMutation, EntityRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EntityRemoveMutation, EntityRemoveMutationVariables>(EntityRemoveDocument, options);
      }
export type EntityRemoveMutationHookResult = ReturnType<typeof useEntityRemoveMutation>;
export type EntityRemoveMutationResult = Apollo.MutationResult<EntityRemoveMutation>;
export type EntityRemoveMutationOptions = Apollo.BaseMutationOptions<EntityRemoveMutation, EntityRemoveMutationVariables>;
export const FeedbackDeleteDocument = gql`
    mutation FeedbackDelete($feedbackDeleteId: Float!) {
  feedbackDelete(id: $feedbackDeleteId) {
    id
  }
}
    `;
export type FeedbackDeleteMutationFn = Apollo.MutationFunction<FeedbackDeleteMutation, FeedbackDeleteMutationVariables>;

/**
 * __useFeedbackDeleteMutation__
 *
 * To run a mutation, you first call `useFeedbackDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeedbackDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [feedbackDeleteMutation, { data, loading, error }] = useFeedbackDeleteMutation({
 *   variables: {
 *      feedbackDeleteId: // value for 'feedbackDeleteId'
 *   },
 * });
 */
export function useFeedbackDeleteMutation(baseOptions?: Apollo.MutationHookOptions<FeedbackDeleteMutation, FeedbackDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeedbackDeleteMutation, FeedbackDeleteMutationVariables>(FeedbackDeleteDocument, options);
      }
export type FeedbackDeleteMutationHookResult = ReturnType<typeof useFeedbackDeleteMutation>;
export type FeedbackDeleteMutationResult = Apollo.MutationResult<FeedbackDeleteMutation>;
export type FeedbackDeleteMutationOptions = Apollo.BaseMutationOptions<FeedbackDeleteMutation, FeedbackDeleteMutationVariables>;
export const FeedbackFindallDocument = gql`
    query FeedbackFindall {
  feedbackFindall {
    ...FeedbackFragment
  }
}
    ${FeedbackFragmentFragmentDoc}`;

/**
 * __useFeedbackFindallQuery__
 *
 * To run a query within a React component, call `useFeedbackFindallQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackFindallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackFindallQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeedbackFindallQuery(baseOptions?: Apollo.QueryHookOptions<FeedbackFindallQuery, FeedbackFindallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackFindallQuery, FeedbackFindallQueryVariables>(FeedbackFindallDocument, options);
      }
export function useFeedbackFindallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackFindallQuery, FeedbackFindallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackFindallQuery, FeedbackFindallQueryVariables>(FeedbackFindallDocument, options);
        }
export type FeedbackFindallQueryHookResult = ReturnType<typeof useFeedbackFindallQuery>;
export type FeedbackFindallLazyQueryHookResult = ReturnType<typeof useFeedbackFindallLazyQuery>;
export type FeedbackFindallQueryResult = Apollo.QueryResult<FeedbackFindallQuery, FeedbackFindallQueryVariables>;
export const FeedbackFindOneDocument = gql`
    query FeedbackFindOne($feedbackFindOneId: Float!) {
  feedbackFindOne(id: $feedbackFindOneId) {
    ...FeedbackFragment
  }
}
    ${FeedbackFragmentFragmentDoc}`;

/**
 * __useFeedbackFindOneQuery__
 *
 * To run a query within a React component, call `useFeedbackFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackFindOneQuery({
 *   variables: {
 *      feedbackFindOneId: // value for 'feedbackFindOneId'
 *   },
 * });
 */
export function useFeedbackFindOneQuery(baseOptions: Apollo.QueryHookOptions<FeedbackFindOneQuery, FeedbackFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedbackFindOneQuery, FeedbackFindOneQueryVariables>(FeedbackFindOneDocument, options);
      }
export function useFeedbackFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedbackFindOneQuery, FeedbackFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedbackFindOneQuery, FeedbackFindOneQueryVariables>(FeedbackFindOneDocument, options);
        }
export type FeedbackFindOneQueryHookResult = ReturnType<typeof useFeedbackFindOneQuery>;
export type FeedbackFindOneLazyQueryHookResult = ReturnType<typeof useFeedbackFindOneLazyQuery>;
export type FeedbackFindOneQueryResult = Apollo.QueryResult<FeedbackFindOneQuery, FeedbackFindOneQueryVariables>;
export const IndustryCreateDocument = gql`
    mutation IndustryCreate($body: CreateIndustryInput!) {
  industryCreate(body: $body) {
    ...IndustryFragment
  }
}
    ${IndustryFragmentFragmentDoc}`;
export type IndustryCreateMutationFn = Apollo.MutationFunction<IndustryCreateMutation, IndustryCreateMutationVariables>;

/**
 * __useIndustryCreateMutation__
 *
 * To run a mutation, you first call `useIndustryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndustryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [industryCreateMutation, { data, loading, error }] = useIndustryCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useIndustryCreateMutation(baseOptions?: Apollo.MutationHookOptions<IndustryCreateMutation, IndustryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndustryCreateMutation, IndustryCreateMutationVariables>(IndustryCreateDocument, options);
      }
export type IndustryCreateMutationHookResult = ReturnType<typeof useIndustryCreateMutation>;
export type IndustryCreateMutationResult = Apollo.MutationResult<IndustryCreateMutation>;
export type IndustryCreateMutationOptions = Apollo.BaseMutationOptions<IndustryCreateMutation, IndustryCreateMutationVariables>;
export const IndustryRemoveDocument = gql`
    mutation IndustryRemove($industryRemoveId: Int!) {
  industryRemove(id: $industryRemoveId) {
    ...IndustryFragment
  }
}
    ${IndustryFragmentFragmentDoc}`;
export type IndustryRemoveMutationFn = Apollo.MutationFunction<IndustryRemoveMutation, IndustryRemoveMutationVariables>;

/**
 * __useIndustryRemoveMutation__
 *
 * To run a mutation, you first call `useIndustryRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndustryRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [industryRemoveMutation, { data, loading, error }] = useIndustryRemoveMutation({
 *   variables: {
 *      industryRemoveId: // value for 'industryRemoveId'
 *   },
 * });
 */
export function useIndustryRemoveMutation(baseOptions?: Apollo.MutationHookOptions<IndustryRemoveMutation, IndustryRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndustryRemoveMutation, IndustryRemoveMutationVariables>(IndustryRemoveDocument, options);
      }
export type IndustryRemoveMutationHookResult = ReturnType<typeof useIndustryRemoveMutation>;
export type IndustryRemoveMutationResult = Apollo.MutationResult<IndustryRemoveMutation>;
export type IndustryRemoveMutationOptions = Apollo.BaseMutationOptions<IndustryRemoveMutation, IndustryRemoveMutationVariables>;
export const IndustryUpdateDocument = gql`
    mutation IndustryUpdate($body: UpdateIndustryInput!, $industryUpdateId: Float!) {
  industryUpdate(body: $body, id: $industryUpdateId) {
    ...IndustryFragment
  }
}
    ${IndustryFragmentFragmentDoc}`;
export type IndustryUpdateMutationFn = Apollo.MutationFunction<IndustryUpdateMutation, IndustryUpdateMutationVariables>;

/**
 * __useIndustryUpdateMutation__
 *
 * To run a mutation, you first call `useIndustryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndustryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [industryUpdateMutation, { data, loading, error }] = useIndustryUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      industryUpdateId: // value for 'industryUpdateId'
 *   },
 * });
 */
export function useIndustryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IndustryUpdateMutation, IndustryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IndustryUpdateMutation, IndustryUpdateMutationVariables>(IndustryUpdateDocument, options);
      }
export type IndustryUpdateMutationHookResult = ReturnType<typeof useIndustryUpdateMutation>;
export type IndustryUpdateMutationResult = Apollo.MutationResult<IndustryUpdateMutation>;
export type IndustryUpdateMutationOptions = Apollo.BaseMutationOptions<IndustryUpdateMutation, IndustryUpdateMutationVariables>;
export const IndustryFindAllDocument = gql`
    query IndustryFindAll {
  industryFindAll {
    ...IndustryFragment
  }
}
    ${IndustryFragmentFragmentDoc}`;

/**
 * __useIndustryFindAllQuery__
 *
 * To run a query within a React component, call `useIndustryFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustryFindAllQuery(baseOptions?: Apollo.QueryHookOptions<IndustryFindAllQuery, IndustryFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryFindAllQuery, IndustryFindAllQueryVariables>(IndustryFindAllDocument, options);
      }
export function useIndustryFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryFindAllQuery, IndustryFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryFindAllQuery, IndustryFindAllQueryVariables>(IndustryFindAllDocument, options);
        }
export type IndustryFindAllQueryHookResult = ReturnType<typeof useIndustryFindAllQuery>;
export type IndustryFindAllLazyQueryHookResult = ReturnType<typeof useIndustryFindAllLazyQuery>;
export type IndustryFindAllQueryResult = Apollo.QueryResult<IndustryFindAllQuery, IndustryFindAllQueryVariables>;
export const IndustryFindOneDocument = gql`
    query IndustryFindOne($industryFindOneId: Int!) {
  industryFindOne(id: $industryFindOneId) {
    ...IndustryFragment
  }
}
    ${IndustryFragmentFragmentDoc}`;

/**
 * __useIndustryFindOneQuery__
 *
 * To run a query within a React component, call `useIndustryFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryFindOneQuery({
 *   variables: {
 *      industryFindOneId: // value for 'industryFindOneId'
 *   },
 * });
 */
export function useIndustryFindOneQuery(baseOptions: Apollo.QueryHookOptions<IndustryFindOneQuery, IndustryFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustryFindOneQuery, IndustryFindOneQueryVariables>(IndustryFindOneDocument, options);
      }
export function useIndustryFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustryFindOneQuery, IndustryFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustryFindOneQuery, IndustryFindOneQueryVariables>(IndustryFindOneDocument, options);
        }
export type IndustryFindOneQueryHookResult = ReturnType<typeof useIndustryFindOneQuery>;
export type IndustryFindOneLazyQueryHookResult = ReturnType<typeof useIndustryFindOneLazyQuery>;
export type IndustryFindOneQueryResult = Apollo.QueryResult<IndustryFindOneQuery, IndustryFindOneQueryVariables>;
export const VideoCreateDocument = gql`
    mutation VideoCreate($body: CreateVideoEntityInput!) {
  videoCreate(body: $body) {
    ...VideoFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${VideoFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;
export type VideoCreateMutationFn = Apollo.MutationFunction<VideoCreateMutation, VideoCreateMutationVariables>;

/**
 * __useVideoCreateMutation__
 *
 * To run a mutation, you first call `useVideoCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCreateMutation, { data, loading, error }] = useVideoCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useVideoCreateMutation(baseOptions?: Apollo.MutationHookOptions<VideoCreateMutation, VideoCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoCreateMutation, VideoCreateMutationVariables>(VideoCreateDocument, options);
      }
export type VideoCreateMutationHookResult = ReturnType<typeof useVideoCreateMutation>;
export type VideoCreateMutationResult = Apollo.MutationResult<VideoCreateMutation>;
export type VideoCreateMutationOptions = Apollo.BaseMutationOptions<VideoCreateMutation, VideoCreateMutationVariables>;
export const VideoUpdateDocument = gql`
    mutation VideoUpdate($body: UpdateVideoEntityInput!, $uuid: String!) {
  videoUpdate(body: $body, uuid: $uuid) {
    ...VideoFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${VideoFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;
export type VideoUpdateMutationFn = Apollo.MutationFunction<VideoUpdateMutation, VideoUpdateMutationVariables>;

/**
 * __useVideoUpdateMutation__
 *
 * To run a mutation, you first call `useVideoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoUpdateMutation, { data, loading, error }] = useVideoUpdateMutation({
 *   variables: {
 *      body: // value for 'body'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useVideoUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VideoUpdateMutation, VideoUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoUpdateMutation, VideoUpdateMutationVariables>(VideoUpdateDocument, options);
      }
export type VideoUpdateMutationHookResult = ReturnType<typeof useVideoUpdateMutation>;
export type VideoUpdateMutationResult = Apollo.MutationResult<VideoUpdateMutation>;
export type VideoUpdateMutationOptions = Apollo.BaseMutationOptions<VideoUpdateMutation, VideoUpdateMutationVariables>;
export const VideoRemoveDocument = gql`
    mutation VideoRemove($uuid: String!) {
  videoRemove(uuid: $uuid) {
    uuid
  }
}
    `;
export type VideoRemoveMutationFn = Apollo.MutationFunction<VideoRemoveMutation, VideoRemoveMutationVariables>;

/**
 * __useVideoRemoveMutation__
 *
 * To run a mutation, you first call `useVideoRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoRemoveMutation, { data, loading, error }] = useVideoRemoveMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useVideoRemoveMutation(baseOptions?: Apollo.MutationHookOptions<VideoRemoveMutation, VideoRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoRemoveMutation, VideoRemoveMutationVariables>(VideoRemoveDocument, options);
      }
export type VideoRemoveMutationHookResult = ReturnType<typeof useVideoRemoveMutation>;
export type VideoRemoveMutationResult = Apollo.MutationResult<VideoRemoveMutation>;
export type VideoRemoveMutationOptions = Apollo.BaseMutationOptions<VideoRemoveMutation, VideoRemoveMutationVariables>;
export const VideoFindAllDocument = gql`
    query VideoFindAll {
  videoFindAll {
    ...VideoFragment
    authors {
      ...AuthorFragment
    }
  }
}
    ${VideoFragmentFragmentDoc}
${AuthorFragmentFragmentDoc}`;

/**
 * __useVideoFindAllQuery__
 *
 * To run a query within a React component, call `useVideoFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useVideoFindAllQuery(baseOptions?: Apollo.QueryHookOptions<VideoFindAllQuery, VideoFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VideoFindAllQuery, VideoFindAllQueryVariables>(VideoFindAllDocument, options);
      }
export function useVideoFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideoFindAllQuery, VideoFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VideoFindAllQuery, VideoFindAllQueryVariables>(VideoFindAllDocument, options);
        }
export type VideoFindAllQueryHookResult = ReturnType<typeof useVideoFindAllQuery>;
export type VideoFindAllLazyQueryHookResult = ReturnType<typeof useVideoFindAllLazyQuery>;
export type VideoFindAllQueryResult = Apollo.QueryResult<VideoFindAllQuery, VideoFindAllQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    