import React from 'react';
import './App.scss';
import {Layout} from "antd";
import {AppRouter} from "./app/providers/router";
import {Sidebar} from "./components/layout/sidebar";
import {Auth} from "./pages/Auth";
import {useAuthContext} from "./app/providers/auth/lib/AuthContext";
import {UserProfile} from "./components/user/ui/UserProfile";

const {Header, Content, Footer} = Layout;

function App() {

    const {isLoggedIn} = useAuthContext();

    return (
        isLoggedIn
            ? <Layout className='App-layout'>
                <Sidebar className='App-sidebar'/>
                <Layout>
                    <Header className='App-header'>
                        <UserProfile className="App-profile"/>
                    </Header>
                    <Content className='App-content'>
                        <AppRouter/>
                    </Content>
                    <Footer className='App-footer'>©2022</Footer>
                </Layout>
            </Layout>
            : <Auth/>
    );
}

export default App;
