import cls from './UserProfile.module.scss'
import {Avatar, Dropdown, MenuProps, Space} from "antd";
import React from "react";
import {classNames} from "../../../lib/ClassNames/ClassNames";
import {ExportOutlined, UserOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {RoutePath} from "../../../app/providers/router/config/routeConfig";
import {useAuthContext} from "../../../app/providers/auth/lib/AuthContext";

interface UserProfileProps {
    className?: string;
}

export const UserProfile = ({className = ''}: UserProfileProps) => {
    const navigate = useNavigate();
    const {isLoggedIn, logout} = useAuthContext();

    const items: MenuProps['items'] = [
        {
            key: 'profile',
            icon: <UserOutlined/>,
            label: 'Профиль',
            onClick: () => navigate(RoutePath.profile)
        },
        {
            type: 'divider',
        },
        {
            key: 'logout',
            icon: <ExportOutlined/>,
            label: 'Выйти',
            onClick: () => onLogout()
        },
    ]


    const onLogout = () => {
        logout().then(() => {

        })
    }

    return (
        <div className={classNames(cls.UserProfile, {}, [className])}>
            {isLoggedIn
                ? (
                    <Dropdown menu={{items}}
                              placement="topRight">
                        <a href="/" onClick={e => e.preventDefault()} style={{display: "inline-flex"}}>
                            <Space>
                                <Avatar icon={<UserOutlined/>}/>
                            </Space>
                        </a>

                    </Dropdown>
                )
                : null
            }
        </div>
    );
};
